/**
 * Organisation Domain Module
 * 
 * @copyright DigitalPeti Technologies Pvt Ltd.
 */
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from 'src/app/core/security/authguard';
import { MembersComponent } from './members/members.component';
import { NotesBoardComponent } from './notes-board/notes-board.component';
import { SchemesComponent } from './schemes/schemes.component';
import { SponserComponent } from './sponser/sponser.component';
import { SummaryComponent } from './summary/summary.component';
import {BankComponent} from './bank/bank.component'

const routes: Routes = [
    {
        path: 'org',
        children: [{
            path: 'member',
            component: MembersComponent
        },
        {
            path: 'notes',
            component: NotesBoardComponent
        },
        {
            path: 'scheme',
            component: SchemesComponent
        },
        {
            path: 'sponsor',
            component: SponserComponent
        },
        {
            path: 'bank',
            component: BankComponent
        },
        {
            path: 'summary',
            component: SummaryComponent
        }]
    }];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class OrgRoutingModule { }
