import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({name: 'hhmm'})
export class HHMMPipe implements PipeTransform {
  transform(value: string): string {
    if (value) {
      return value.split(" ")[1].slice(0,5);
    } else {
      return value;
    }
  }
}