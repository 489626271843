/**
 *  Authorization Guard Service
 * 
 * @copyright Digitalpeti Technologies Ltd.
 */
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { BaseService } from '../services/base.service';
import { UserProfileService } from '../../providers/service/user-profile.service';
import { SharedService } from 'src/app/providers/service/shared-services.service';

@Injectable()
export class AuthGuardService extends BaseService implements CanLoad, CanActivate, CanActivateChild {

    /**
     * Initialize
     * 
     * @param router Router
     */
    constructor(private router: Router,
        private userProfileService: UserProfileService,
        private sharedService: SharedService) {
        super();
    }

    /**
     * Can Load
     */
    public canLoad(route: Route): boolean {
        return true;
    }

    /**
     * Can Activate
     * 
     * @param route Route
     * @param state State
     */

    // public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    //     const enableGuard: boolean = SharedDataCacheService.getProperty(this.applicationConfigurationService.ENABLE_GUARD);
    //     //
    //     if (enableGuard === true) {
    //         const exclusionList: Array<string> = SharedDataCacheService.getRouterExclusionList();
    //         const url: string = state.url;
    //         let isExcluded: boolean = false;
    //         const unAuthURLVariable: string = '/unauth';
    //         // Check Exclusion List
    //         if (exclusionList) {
    //             try {
    //                 exclusionList.forEach((excludedURL: string) => {
    //                     if (excludedURL === url) {
    //                         isExcluded = true;
    //                         // Break the Loop
    //                         throw new Error('Excluded URL');
    //                     }
    //                 });
    //             } catch (e) { }
    //         }
    //         if (!isExcluded) {
    //             const trimmedURL: string = RouterUtility.trimURL(url);
    //             // Verify for Unauthorized URL
    //             if (trimmedURL.lastIndexOf(unAuthURLVariable) === trimmedURL.length - unAuthURLVariable.length) {
    //                 isExcluded = true;
    //             }
    //         }
    //         if (isExcluded) {
    //             return true;
    //         }
    //         if (!SecurityUtility.isAuthorized()) {
    //             const trimmedURL: string = RouterUtility.trimURL(url);
    //             // Redirect to Un-Auth URL
    //             // RouterUtility.navigateTo(this.router, `${trimmedURL}${unAuthURLVariable}`, {}, Utility.generateUUID());
    //             // Not Authorized
    //             return false;
    //         } else {
    //             const trimmedURL: string = RouterUtility.trimURL(url);
    //             //
    //             if (trimmedURL && trimmedURL.indexOf(unAuthURLVariable) >= 0) {
    //                 const newAuthURL: string = trimmedURL.substring(0, trimmedURL.indexOf(unAuthURLVariable));
    //                 // Redirect to Un-Auth URL
    //                 // RouterUtility.navigateTo(this.router, `${newAuthURL}`, {}, Utility.generateUUID());
    //                 // Not Authorized
    //                 return false;
    //             }
    //         }
    //     }
    //     return true;
    // }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

       
        console.log("can activate"+next.routeConfig.path);
        console.log(state);
        if (this.userProfileService.isLoggedIn)
        {
            this.sharedService.setshowlogin(false);
            return true;
        }        
        else
        {
            this.sharedService.setshowlogin(true);
            this.router.navigate(['']);
            return false;
        }      

        let hasPermission: boolean = false;
        const route = next.routeConfig.path;
        hasPermission = this.userProfileService.hasPermission(route);
        return hasPermission;
    }

    /**
     * Can Activate Child
     * 
     * @param route Route
     * @param state State
     */
    public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        console.log("canActivateChild");
        return true;
    }
}