/**
 * Staff Domain Module
 * 
 * @copyright Digitalpeti Technologies Ltd.
 */
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from 'src/app/core/security/authguard';
import { TradeProfileComponent } from './trade-profile/trade-profile.component';
import { MemberProfileComponent } from './members-profile/member-profile.component';
import { MemberListComponent } from './members-list/member-list.component';
import { MMProfileComponent } from './mm-profile/mm-profile.component';
const routes: Routes = [
    {
        path: 'members',
        children: [{
            path: 'trade',
            component: TradeProfileComponent,
            canActivate: [AuthGuardService]
        },
        {
            path: 'materimonial',
            component: MMProfileComponent,
            canActivate: [AuthGuardService]
        },
        
    {
            path: 'memberprofile',
            component: MemberProfileComponent,
            canActivate: [AuthGuardService]
        },
        {
            path: 'memberprofilelist',
            component: MemberListComponent,
            canActivate: [AuthGuardService]
        }]
    }];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class MemberRoutingModule { }
