/**
 * Component
 * 
 * @copyright Digitalpeti Technologies Ltd.
 */

import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { LoaderService } from '../../../providers/service/loader/loader.service';
import { ApplicationConfigurationService } from '../../../common/services/application-configuration.service'; 

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  color :any; // The color of a progress-spinner can be changed by using the color property
  mode :any; // To control Spinner mode [determinate,indeterminate]
  value :any // To control Spinner progress value
  /**
   *  Flag to control loader on every HTTP request
   */
  isLoading: Subject<boolean> = this.loaderService.isLoading;
  
  constructor(private loaderService: LoaderService,
    private applicationConfigurationService :ApplicationConfigurationService,){
      this.color = this.applicationConfigurationService.SPINNER_COLOR;
      this.mode = this.applicationConfigurationService.SPINNER_MODE;
      this.value = this.applicationConfigurationService.SPINNER_VALUE;
    }
  ngOnInit() {
  }

}
