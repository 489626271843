import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { UsersOrgService } from 'src/app/providers/service/users.org.service';
import { SharedService } from 'src/app/providers/service/shared-services.service';
import { UserProfileService } from 'src/app/providers/service/user-profile.service';
import {MatDialog} from '@angular/material';
//import { Logger } from 'src/app/common/services/logger';
import { UserOrganisation } from '../../../../providers/cdm/userOrganisation';
import { LoggingService } from '../../../../providers/service/logger/logging.service';
import { CommonService } from '../../../../providers/service/common-services.service';

@Component({
  selector: 'app-passbook',
  templateUrl: './passbook.component.html',
  styleUrls: ['./passbook.component.scss']
})
export class PassbookComponent implements OnInit {
  rowData: any;
  columnDefs = [
    { headerName: 'Date', field: 'date', sortable: true, filter: true },
    { headerName: 'Amount', field: 'amount', sortable: true, filter: true },
    { headerName: 'Purpose', field: 'purpose', sortable: true, filter: true }
  ];
  rowData1: any;
  columnDefs1 = [
    { headerName: 'Date', field: 'date', sortable: true, filter: true },
    { headerName: 'Amount', field: 'amount', sortable: true, filter: true },
    { headerName: 'Purpose', field: 'purpose', sortable: true, filter: true },
    { headerName: 'Payment Method', field: 'type', sortable: true, filter: true },
    { headerName: 'Print Receipt', field: '', sortable: true, filter: true },
    { headerName: 'Transaction Id', field: 'transactionid', sortable: true, filter: true }
  ];
  userOrganisation: UserOrganisation=null;
  
  constructor(
    public userData: UserProfileService,
    public logger: LoggingService,
    public translate : TranslateService,
    public commonService: CommonService,
    ) {}

 ngOnInit(): void {
  this.getOrgData();
}

getOrgData() {
  this.commonService.getUserOrganisationDetails()
    .subscribe((res) => {
      console.log(" response data" + JSON.stringify(res));
      this.userOrganisation = res['organisation'];
      this.rowData = this.userOrganisation.deposit;
      this.rowData1= this.userOrganisation.paid;
      console.log(" response data11" + this.userOrganisation);
    })
}
 
  /* get data from server */
  getUserOrganisationDetails() {
   /* return this.usersOrgService.getOrganisationDetails(this.userData.selectedOrganisation.userName,
      this.userData.selectedOrganisation.organisation, this.userData.token,this.userData.hostName)
      .then(response => {
        this.logger.log(this.logger.DEBUG, "peti:getUserOrganisationDetails" + response);
        if (response.errors) {
          this.logger.logData(this.logger.ERROR, "peti:getUserOrganisationDetails", response.errors);
        }
        if (response.organisation) {
          this.userData.selectedOrganisation = response.organisation;
          this.events.publish('peti:PassBook');
        };
      }, error => {
        this.logger.logData(this.logger.ERROR, "BaseComponent:getPiggyBankDataFromServer", error);
      }
      );*/
  }
  
}
