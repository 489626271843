/**
 * CommonFeatureComponent
 *
 * @copyright Digitalpeti Technologies Ltd.
 */
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AccessControlModel } from 'src/app/core/models/data/access-control';
import { UserProfileService } from 'src/app/providers/service/user-profile.service';
import { CommonService } from '../../../providers/service/common-services.service';

@Component({
  selector: 'app-common-feature',
  templateUrl: './common-feature.component.html'
})
export class CommonFeatureComponent implements OnInit, AfterViewInit {

  accessControl: AccessControlModel[] = [];
  currentPath: string = null;
  userProfile: any;
  offset = 0;
  limit = 100;
  loopDataLimit = 500;
  maxCount = 0;
  enableSearch = true;

  constructor(public router: Router,
              public activatedRoute: ActivatedRoute,
              public userProfileService: UserProfileService,
              public commonService: CommonService) {

    this.accessControl['read'] = false;
    this.accessControl['write'] = false;
    this.accessControl['update'] = false;
    this.accessControl['delete'] = false;

    this.currentPath = activatedRoute.snapshot.routeConfig.path;
    this.userProfile = this.userProfileService.getUserProfile();

    if (this.userProfile && this.userProfile.userAccess) {
      this.userProfile.userAccess.forEach(element => {
        if (element.functionCode == this.currentPath && element.access != 'N') {
          if (element.access == 'F') {
            this.accessControl['read'] = true;
            this.accessControl['write'] = true;
            this.accessControl['update'] = true;
            this.accessControl['delete'] = true;
          } else if (element.access == 'R') {
            this.accessControl['read'] = true;
          } else if (element.access == 'P') {
            //let childPermission = element.userPermissionBeanList;
            // childPermission.forEach(async element2 => {
            //   if (element2.functionCode == filedName && element2.access != 'N') {
            //     return true;
            //   }
            // });
          }
        }
      });
    }

    // this.accessControl = userProfileService.checkForAccessControl(this.currentPath, '');

    // if (this.accessControl['read'] == false) {
    //   commonService.showDialogBox('E', 'Unauthorized Access');
    //   this.router.navigate(['/']);
    // }
  }

  ngOnInit() {

  }

  retrieveAllRecords(headers) {
    let loopCount = 0;
    let remainder = 0;
    this.enableSearch = false;
    this.maxCount = headers.get('recordCount') ? JSON.parse(headers.get('recordCount')) : 0;
    if (this.maxCount < this.loopDataLimit && this.maxCount > this.limit) {
      loopCount = 1;
    } else {
      loopCount = Math.floor((this.maxCount - this.limit) / this.loopDataLimit);
      remainder = (this.maxCount - this.limit) % this.loopDataLimit;
    }
    if (remainder !== 0) {
      loopCount = loopCount + 1;
    }
    for (let i = 0; i < loopCount ; i++ ) {
      if (i === 0) {
        this.offset =  this.offset + this.limit;
      } else {
        this.offset =  this.offset + this.loopDataLimit;
      }
      this.getMoreData();
    }
  }

  checkRecordCount(recordList) {
    if (recordList.length === this.maxCount) {
      this.enableSearch =  true;
    }
  }

  getMoreData() {
  }

  ngAfterViewInit() {

  }
}
