/**
 * Component
 * 
 * @copyright 
 */
import { Component, OnInit } from '@angular/core';
import { UserProfileService } from 'src/app/providers/service/user-profile.service';
import { CommonService } from '../../../providers/service/common-services.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from 'src/app/providers/service/shared-services.service';

@Component({
  selector: 'app-trade-profile',
  templateUrl: './trade-profile.component.html',
  styleUrls: ['./trade-profile.component.scss']
})
export class TradeProfileComponent implements OnInit {
  rowData: any;
  public searchForm: FormGroup;
  public searchExpanded = true;
  public disableSearch: boolean = false;
  private lang: string;

  columnDefs = [
    { headerName: 'Make', field: 'make', sortable: true, filter: true },
    { headerName: 'Model', field: 'model', sortable: true, filter: true },
    { headerName: 'Price', field: 'price', sortable: true, filter: true }
  ];

  constructor(
    public userProfileService: UserProfileService,
    public commonService: CommonService,
    http: HttpClient,
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private sharedService: SharedService,
  ) {
    this.lang = this.sharedService.getSelectedLanguage();
    translateService.setDefaultLang(this.lang);
  }

  ngOnInit() {
    this.initializeForm();
    this.getMembersData();
  }

  /**
   * Initialize search Form
   */
  initializeForm() {
    this.searchForm = this.formBuilder.group({
      airlineId: [],
    });
  }
  
  /**
   * Get form data and convert into object
   */
  getFormData() {
    let searchFormObj = Object.assign({}, this.searchForm.value as MemberList);
    return searchFormObj;
  }

  /**
    * Function to get allotment list .
    */
   search() {
    this.getMembersData();
  }

  /**
	  * Function to reset search form.
	  */
   clearFields(searchForm) {
    this.initializeForm();
  }

  /**
	  * Toggle Title Bar
	  */
   toggleSearchBar(btnInd) {
    if (btnInd === 'search') {
      this.searchExpanded = !this.searchExpanded;
    }
  }

  /* 
    Function to update last visted page by user
  */
 getMembersData() {
  this.commonService.getMembersList(this.getFormData())
    .subscribe((res) => {
      console.log(" response data" + res);
      this.rowData = res.data;
    })
}
}

export interface MemberList {
  airlineId: string;
}
