/**
 * Component
 *
 * @copyright
 */
import { Component, OnInit,ViewChild } from '@angular/core';
import { FirebaseService } from '../../../providers/service/firebase.service';
import { Router, Params } from '@angular/router';
import {CommonServicesService} from "../../../core/services/common-services.service";
import {MatSort} from '@angular/material/sort';
import { AngularFireDatabase} from '@angular/fire/database';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import {member} from './member';
import { MatDialog } from '@angular/material';
import {MemberDialogProfileComponent} from './dialog-profile/member-profile.component';
@Component({
  selector: 'app-member-list',
  templateUrl: './member-list.component.html',
  styleUrls: ['./member-list.component.scss']
})
export class MemberListComponent implements OnInit {

  private dataSource: any;
  users: any;
  members:member[]=[];

  constructor(public firebaseService: FirebaseService,public dialog: MatDialog,) {
  }

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  ngOnInit() {
    this.getData();
  }

  displayedColumns: string[] = ['fullName', 'state', 'district', 'city', 'email', 'socialMedia','action'];

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    console.log(filterValue);
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  openDialog(update,element)
  {
      const dialogRef = this.dialog.open(MemberDialogProfileComponent,{height: '400px',
      width: '600px',data :{'cusId':element.cusId}});
  }
  getData() {
    this.firebaseService.getCustomers()
      .subscribe(result => {
        console.log("lcjain");
        result.forEach(element => {
          let member:any=element.payload.doc.data();
          this.members.push(member);
          console.log(element.payload.doc.data());
        });
        this.dataSource = new MatTableDataSource(this.members);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort=this.sort;
      })
  }

  // getTableData() {
  //   this.commonServicesService.getTableData().subscribe(
  //     (response)=>{
  //       console.log("success");
  //       this.dataSource = new MatTableDataSource(response);
  //       this.dataSource.paginator = this.paginator;
  //       this.dataSource.sort=this.sort;
  //     },
  //     error=>{
  //       console.log(error.status);
  //     }
  //   )
  // }


  // ageValue: number = 0;
  // searchValue: string = "";
  // items: Array<any>;
  // age_filtered_items: Array<any>;
  // name_filtered_items: Array<any>;
  //
  // constructor(
  //   public firebaseService: FirebaseService,
  //   private router: Router
  // ) { }
  //
  // ngOnInit() {
  //   this.getData();
  // }
  //
  // getData(){
  //   this.firebaseService.getUsers()
  //   .subscribe(result => {
  //     this.items = result;
  //     this.age_filtered_items = result;
  //     this.name_filtered_items = result;
  //   })
  // }
  //
  // viewDetails(item){
  //   this.router.navigate(['/details/'+ item.payload.doc.id]);
  // }
  //
  // capitalizeFirstLetter(value){
  //   return value.charAt(0).toUpperCase() + value.slice(1);
  // }
  //
  // searchByName(){
  //   let value = this.searchValue.toLowerCase();
  //   this.firebaseService.searchUsers(value)
  //   .subscribe(result => {
  //     this.name_filtered_items = result;
  //     this.items = this.combineLists(result, this.age_filtered_items);
  //   })
  // }
  //
  // rangeChange(event){
  //   this.firebaseService.searchUsersByAge(event.value)
  //   .subscribe(result =>{
  //     this.age_filtered_items = result;
  //     this.items = this.combineLists(result, this.name_filtered_items);
  //   })
  // }
  //
  // combineLists(a, b){
  //   let result = [];
  //
  //   a.filter(x => {
  //     return b.filter(x2 =>{
  //       if(x2.payload.doc.id == x.payload.doc.id){
  //         result.push(x2);
  //       }
  //     });
  //   });
  //   return result;
  // }

}
