
/**
 *  HTTP Interceptor Service
 *
 * @copyright Digitalpeti Technologies Ltd. 2017-18
 */
// Angular
import { Injectable, ViewChild } from '@angular/core';
import { HttpEvent, HttpEventType, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { tap, catchError, finalize, timeout } from 'rxjs/operators';
// Core
import { BaseService } from '../base.service';
import { SharedDataCacheService } from '../../services/shared-data-cache.service';
import { SecurityUtility } from '../../util/security.utility';
import { NGXLogger } from 'ngx-logger';
import { GlobalErrorHandler } from 'src/app/providers/service/exception-handler/global-error-handler';
import { LoaderService } from '../../../providers/service/loader/loader.service';
import { CommonService } from 'src/app/providers/service/common-services.service';
import { ApplicationConfigurationService } from 'src/app/common/services/application-configuration.service';
import { UserProfileService } from '../../../providers/service/user-profile.service';
import { SharedService } from 'src/app/providers/service/shared-services.service';
import { Utility } from '../../util/core.utility';
import { LoginService } from 'src/app/domain/digitalpeti/login/login.service';

/**
 *  HTTP Interceptor Service
 */
@Injectable()
export class HTTPInterceptorService extends BaseService implements HttpInterceptor {

    public timeoutDuration :any;
    public refreshTokenHeader: any;
    /**
     * Initialize
     */
    constructor(private logger: NGXLogger,
        private errorHandler: GlobalErrorHandler,
        private commonService: CommonService,
        private loaderService: LoaderService,
        private sharedService: SharedService,
        public loginService: LoginService,
        public userProfileService: UserProfileService,
        private applicationConfigurationService :ApplicationConfigurationService) {
        super();
        this.timeoutDuration= this.applicationConfigurationService.HTTP_TIMEOUT_DURATION;
    }

    /**
     * Intercept Http Request
     *
     * @param request Request
     * @param next Next Http Handler
     */
    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // New Cloned HTTP Request
        console.log("Laxmi C Jainhttp intercepter");
        const timestamp = request.headers.get('timestamp') ? request.headers.get('timestamp') : '';
        const refeshToken = request.headers.get('refreshToken') ? request.headers.get('refreshToken') : '';
        this.refreshTokenHeader = refeshToken;
        let offset = request.headers.get('offset');
        if (offset) {
            offset = request.headers.get('offset');
        } else if (offset == JSON.parse('0')) {
            offset = request.headers.get('offset');
        } else {
            offset = '';
        }
        let limit = request.headers.get('limit') ? request.headers.get('limit') : '';
        let disableLoader = request.headers.get('disableLoader') ? request.headers.get('disableLoader') : '';
        let httpHeaders = {
            'X-Auth-User-Id': 'System',
            'X-Auth-Staff-Id': '',
            'Authorization': 'Bearer ',
            'X-Auth-Session-Id': '',
            'X-Auth-Transaction-Id': this.sharedService.date().getTime().toString(),
            'X-Time-Zone-Offset': '' +this.sharedService.date().getTimezoneOffset(),
            'X-Auth-User-Type': '',
            'Channel-Id': 'WEB',
            'APP_VERSION': '1.0',
        };

        if (this.userProfileService && this.userProfileService.userProfile && this.userProfileService.userProfile['userId']) {
            httpHeaders['X-Auth-User-Id']   = this.userProfileService.userProfile['userId'];
        }
       
        if (this.userProfileService && this.userProfileService.userProfile && this.userProfileService.userProfile['staffNumber']) {
            httpHeaders['X-Auth-Staff-Id'] = this.userProfileService.userProfile['staffNumber'];
        }

        if (this.userProfileService && this.userProfileService.accessToken) {
            httpHeaders['Authorization'] = 'Bearer ' + this.userProfileService.accessToken;
        }

        if (this.userProfileService && this.userProfileService.sessionId) {
            httpHeaders['X-Auth-Session-Id'] = this.userProfileService.sessionId;
        }

        if (timestamp != null && timestamp != '') {
            httpHeaders['lastUpdatingDateTime'] = timestamp;
        }

        if (offset || offset === JSON.parse('0')) {
            httpHeaders['offset'] = JSON.stringify(offset);
        }

        if (limit != null && limit != '') {
            httpHeaders['limit'] = JSON.stringify(limit);
        }

        if (request.url.includes('auth/v1/login')) {
            httpHeaders['GRANT-TYPE'] = 'PASSWORD';
        }

        if (refeshToken != null && refeshToken != '') {
            httpHeaders['GRANT-TYPE'] = 'REFRESH_TOKEN';
        }

        const clonedRequest = request.clone({
            headers: new HttpHeaders(httpHeaders)
        });
        if (request.url.includes('auth/v1')) {
            //Hide loader on Auth service call
            this.loaderService.show();
            return next.handle(clonedRequest).pipe(tap((event: HttpEvent<any>) => {
                this.handleHTTPEvents(request.url, event);
            })).pipe(
                //timeout(120000),  //timeoutDuration 15 min
                catchError(error => {
                    return this.errorHandler.handleErrorNoPopup(error);
                })).pipe(
                    //Hide loader after end of service call
                    finalize(() => this.loaderService.hide())
                );
        } else if (request.url.includes('roster/uploadfile')) {
            //Show loader on Auth service call
            this.loaderService.show();
            return next.handle(clonedRequest).pipe(tap((event: HttpEvent<any>) => {
                this.handleHTTPEvents(request.url, event);
            })).pipe(catchError(error => {
                return this.errorHandler.handleErrorRosterPopup(error);
            })).pipe(
                //Hide loader after end of service call
                finalize(() => this.loaderService.hide())
            );
        } else if (request.url.includes('flightTaskAssignmentRefresh') || request.url.includes('visitedfunction') || request.url.includes('odcSecond')) {
            //Hide loader on service call
            this.loaderService.hide();
            return next.handle(clonedRequest).pipe(tap((event: HttpEvent<any>) => {
                this.handleHTTPEvents(request.url, event);
            })).pipe(catchError(error => {
                return this.errorHandler.handleErrorNoPopup(error);
            })).pipe(
                //Hide loader after end of service call
                finalize(() => this.loaderService.hide())
            );
        } else {
            //Show loader on service call
            if(!disableLoader) 
             this.loaderService.show();
            // Handle HTTP Events 
            return next.handle(clonedRequest).pipe(tap((event: HttpEvent<any>) => {
                // if (event['status'] == 205) {
                //     return event['body'] = 'Concurrency';
                // }
                this.handleHTTPEvents(request.url, event);
            })).pipe(catchError(error => {
                if (error.status === 200) {
                    return [];
                } else {
                    return this.errorHandler.handleError(error);
                }
            })).pipe(
                //Hide loader after end of service call
                finalize(() => this.loaderService.hide())
            );
        }
    }

    /**
     * Handle HTTP Events
     *
     * @param url URL
     * @param event Event
     */
    private handleHTTPEvents(url: string, event: HttpEvent<any>) {
        if (event['status'] == 205) {
            this.commonService.showDialogBox('E', 'Operation failed because another user has updated or deleted the record. Your changes have been lost. Please reload updated data and try again');
        }

        if (this.refreshTokenHeader == '') {
            this.sharedService.setLastAccessTime(this.sharedService.time());
        }

        let apiAccessTime = this.sharedService.time();

        if (this.sharedService.getDefaultSessionTimeout()) {
            let minuteDiff = this.sharedService.getDefaultSessionTimeout() - apiAccessTime;
            minuteDiff = Math.round(minuteDiff / 60000);

            if (minuteDiff > 0 && minuteDiff <= 5 && this.refreshTokenHeader == '') {
                // this.loginService.getRefreshToken();
            }
        }

        switch (event.type) {
            case HttpEventType.Sent:
                // Update Analysis Data
                // SharedDataCacheService.updateHTTPRequestCount();
                // Log
                this.logger.debug(`Request Sent: ${url}`);
                break;
            case HttpEventType.UploadProgress:
                // Update Analysis Data
                // SharedDataCacheService.updateHTTPDownloadUploadSize(url, 0, event.loaded);
                // Log
                // const percentUploaded = Math.round(100 * event.loaded / event.total);
                // console.debug(`Uploaded: ${percentUploaded ? percentUploaded : 0}% ${url}`);
                break;
            case HttpEventType.ResponseHeader:
                this.logger.debug(`Response Header Received: ${url}`);
                break;
            case HttpEventType.DownloadProgress:
                // Update Analysis Data
                // SharedDataCacheService.updateHTTPDownloadUploadSize(url, event.loaded, 0);
                // Log
                //const percentDownloaded = Math.round(100 * event.loaded / event.total);
                // console.debug(`Downloaded: ${percentDownloaded ? percentDownloaded : 0}% ${url}`);
                break;
            case HttpEventType.Response:
                // Update Analysis Data
                // SharedDataCacheService.updateHTTPResponseCount();
                // Log
                this.logger.debug(`Completed: ${url}`);
                break;
        }
    }

    /**
     * Handle HTTP Error
     *
     * @param url URL
     * @param error Error
     */
    // private handleError(error: any) {
    //     let errMsg: string;
    //     //
    //     if (error instanceof HttpErrorResponse) {
    //         // Check for Server Availability
    //         if (error.status === 0) {
    //             errMsg = `Unable to contact Server. Please verify whether Server is up or down.`;
    //         } else {
    //             errMsg = `Unexpected error occured on Server. HTTP Status ${error.status} - ${error.statusText} ${url}`;
    //         }
    //     } else {
    //         errMsg = error.message ? error.message : error.toString();
    //     }
    //     //
    //     return throwError(errMsg);
    // }
}
