import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LoggingService {

    logError(message: string, stack: string) {
        // Send errors to server here
        console.log('LoggingService: ' + message);
    }

    DEBUG:number=1;
    ERROR:number=2;
    debug(data:any)
    {
      console.log(data);
    }
  
  log(type:number,className:any)
    {
        console.log(className );
    }
    logData(type:number,className:any,data:any)
    {
        console.log(data +JSON.stringify(data));
    }
    error(data:any)
    {
      console.log(data);
    }
}
