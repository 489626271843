/**
 * UserProfileService
 * 
 * @copyright Digitalpeti Technologies Ltd.
 */
import { Injectable } from '@angular/core';
import { UserProfile } from '../../core/models/base/user-profile.model';
import { Observable } from 'rxjs';
import { AccessControlModel } from '../../core/models/data/access-control';
import { AccessControl } from '../../core/models/data/access.data';
import { SharedService } from 'src/app/providers/service/shared-services.service';
import { Utility } from '../../core/util/core.utility';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {
  isLoggedIn: Boolean = false;
  sessionId: string;
  channelId: string;
  public accessToken: string;
  refreshToken: string;
  sessionTimeout: number;
  maximumSessionTimeout: any;
  userProfile: UserProfile[] = [];
  loginTime: any;
  orgId:string="910500";
  public mobileNumber:Number;
  public isShowlogin:boolean=false;

  constructor(public sharedService: SharedService,
    private router: Router,) {
  }
  setusername(mobilenumber)
  {
    console.log("setusername -moble number"+mobilenumber);
    this.mobileNumber=mobilenumber;
  }

  setToken(accessToken)
  {
    this.accessToken=accessToken;
  }
  setUserProfile(profile:any) {
    console.log("@@@@@@@@@@@@@@@@@@@@@"+JSON.stringify(profile['Profile']));
    this.sessionId = "1234wrererere";
    this.channelId = "web";
    this.accessToken = profile.Token;
    this.refreshToken = profile.Token;
    this.sessionTimeout = 0;
    this.userProfile['userId'] = profile['Profile'].userId;
    console.log("----------------"+JSON.stringify(profile));
    this.userProfile['userName'] = profile.Profile.firstname;
    this.userProfile['staffNumber'] = profile.Profile.username;
    this.userProfile['mobileNumber'] = profile.Profile.username;
    this.userProfile['emailAddress'] = profile.Profile.email;
    this.userProfile['staffName'] = profile.Profile.firstname;
    this.userProfile['roles'] = profile.Profile.userType;
    this.userProfile['state'] = profile.Profile.state;
    this.userProfile['district'] = profile.Profile.district;
    this.userProfile['city'] = profile.Profile.city;


    this.userProfile['passwordResetRequired'] = profile['Profile'].verificationRequired;
    this.userProfile['lastLogin'] = profile['Profile'].lastLogin;
    this.loginTime = this.sharedService.time();
    //TODO TIMEZONE
    let timeout = this.sharedService.date().setMinutes(this.sharedService.date().getMinutes() + this.sessionTimeout);
    this.maximumSessionTimeout = new Date(timeout).getTime();
    this.sharedService.setDefaultSessionTimeout(new Date(timeout).getTime());
  }

  setUserAcess(access) {
    this.userProfile['userAccess'] = [];
    this.userProfile['userAccess'] = access;
  }

  getUserProfile() {
    return this.userProfile;
  }

  setRefreshToken(profile) {
    this.sessionId = profile.sessionId;
    this.channelId = profile.channelId;
    this.accessToken = profile.accessToken;
    this.refreshToken = profile.refreshToken;
    this.sessionTimeout = profile.userProfile.sessionTimeout;

    let timeout = this.sharedService.date().setMinutes(this.sharedService.date().getMinutes() + this.sessionTimeout);
    this.maximumSessionTimeout = new Date(timeout).getTime();
    this.sharedService.setDefaultSessionTimeout(new Date(timeout).getTime());
  }

  hasPermission(routeName: string): boolean {
    let hasAccess: boolean = true;
    if (this.userProfile) {
      this.userProfile['userAccess'].forEach(element => {
        if (element.functionCode == routeName && element.access != 'N') {
          hasAccess = true;
        }
        if(element['subfunctions']) {
          element['subfunctions'].forEach(subfunction => {
            if (subfunction.functionCode == routeName && subfunction.access != 'N') {
              hasAccess = true;
            }
          });
        }
      });
      return hasAccess;
    }
  }

  hasNextLevelPermission(routeName: string, filedName: string): boolean {
    let hasAccess: boolean = true;
    if (this.userProfile && this.userProfile['userAccess']) {
      this.userProfile['userAccess'].forEach(element => {
        if (element.functionCode == routeName && element.access != 'N') {
          if (element.access == 'F') {
            hasAccess = true;
            return true;
          } else if (element.access == 'R') {
            hasAccess = false;
            return false;
          } else {
            let childPermission = element.userPermissionBeanList;
            if (childPermission) {
              childPermission.forEach(element2 => {
                if (element2.functionCode == filedName && element2.access != 'N') {
                  hasAccess = true;
                  return true;
                }
              });
            }
          }
        }
      });
      return hasAccess;
    }
  }

  checkForAccessControl(routeName: string, filedName: string) {
    let accessControl: AccessControlModel[] = [];
    accessControl['read'] = true;
    accessControl['write'] = true;
    accessControl['update'] = true;
    accessControl['delete'] = true;

    if (this.userProfile) {
      this.userProfile['userAccess'].forEach(element => {
        if (element.functionCode == routeName && element.access != 'N') {
          if (element.access == 'F') {
            accessControl['read'] = true;
            accessControl['write'] = true;
            accessControl['update'] = true;
            accessControl['delete'] = true;
          } else if (element.access == 'R') {
            accessControl['read'] = true;
          } else if (element.access == 'P') {
            let childPermission = element.userPermissionBeanList;
            childPermission.forEach(async element2 => {
              if (element2.functionCode == filedName && element2.access != 'N') {
                return true;
              }
            });
          }
        }
      });
      return accessControl;
    }
  }

  getCarrierCodeFromFlightNo(flightNumber): any {
    return flightNumber.substr(0, 2);
  }

  getNumberOnlyFromFlightNo(flightNumber): any {
    return flightNumber.substr(2);
  }

  // Logout user and redirect to login
  logOut() {
    this.isLoggedIn = false;
    this.router.navigate(['/']);
  }
}


