/**
 * Service Utility
 *
 * @copyright Digitalpeti Technologies Ltd. 2017-18
 */
// Angular
import { ReflectiveInjector, NgZone } from '@angular/core';
// Core
import { RestfulService } from '../services/restful/rest.service';
import { EventService } from '../services/event.service';
//

/**
 * Service Utility
 */
export abstract class ServiceUtility {
    private static restService: RestfulService = null;
    private static eventService: EventService = null;
    private static zone: NgZone = null;

    /**
     * Update Rest Service
     *
     * @param restService Rest Service
     */
    public static resolveRestService(restService: RestfulService): RestfulService {
        if (!ServiceUtility.restService) {
            ServiceUtility.restService = restService;
        }        
        return ServiceUtility.restService;
    }

    /**
     * Get Rest Service
     */
    public static getRestService(): RestfulService {
        return ServiceUtility.restService;
    }

    /**
     * Gets Event Service
     */
    public static getEventService(): EventService {
        if (ServiceUtility.eventService) {
            return ServiceUtility.eventService;
        }
        try {
            // TODO! Need to Upgrade (Deprecated)
            let injector = ReflectiveInjector.resolveAndCreate([EventService]);
            // Get Event Service
            if (injector) {
                ServiceUtility.eventService = injector.get(EventService);
            }
        } catch (e) {
            console.error("Failed to Load Event Service (Service Utility)");
        }
        return ServiceUtility.eventService;
    }

    /**
     * Resolves/Update Angular Zone
     *
     * @param router Router
     */
    public static resolveAngularZone(zone: NgZone) {
        ServiceUtility.zone = zone;
    }

    /**
     * Get Angular Zone
     */
    public static getAngularZone(): NgZone {
        return ServiceUtility.zone;
    }
}
