/**
 *  Rest HTTP Service
 *
 * @copyright Digitalpeti Technologies Ltd. 2017-18
 */
// Angular
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
// Core
import { BaseRequest } from '../../models/request/base-request.model';
import { BaseResponse, BaseResponseData } from '../../models/response/base-response.model';
// import { BroadcastEvent } from '../event.service';
import { ServiceUtility } from '../../util/service.utility';

/**
 * Restful Service Events
 */
export const RestServiceEvents = {
    POST_BEGIN: 'PostBegin',
    POST_SUCCESS: 'PostSuccess',
    POST_FAILURE: 'PostFailure',
    POST_END: 'PostEnd',
    GET_BEGIN: 'GetBegin',
    GET_SUCCESS: 'GetSuccess',
    GET_FAILURE: 'GetFailure',
    GET_END: 'GetEnd'
};

/**
 * Rest Service Interface
 */
export interface RestfulService {

    /**
     * Gets Notification Observer for Subscription
     */
    // getRestEventObserver(): Observable<BroadcastEvent>;

    /**
     * Gets Data (Async)
     *
     * @param url URL
     * @param data Data
     */
    get(url: string, observe?: string, headerData?: any): Observable<BaseResponse<BaseResponseData>>;

    /**
     * Post Data (Async)
     *
     * @param url URL
     * @param data Data
     */
    post(url: string, data: BaseRequest, observe?: string): Observable<BaseResponse<BaseResponseData>>;

    /**
     * Put Data (Async)
     *
     * @param url URL
     * @param data Data
     */
    put(url: string, data: BaseRequest, observe?: string): Observable<BaseResponse<BaseResponseData>>;

    /**
     * Post Data (Async)
     *
     * @param url URL
     * @param data Data
     */
    cleanAndPost(url: string, data: BaseRequest, observe?: string): Observable<BaseResponse<BaseResponseData>>;
}

/**
 * Rest Service
 */
@Injectable()
export class RestService {

    /**
     * Gets Notification Observer for Subscription
     */
    // getRestEventObserver(): Observable<BroadcastEvent> {
    //     return ServiceUtility.getRestService().getRestEventObserver();
    // }

    /**
     * Gets Data (Async)
     *
     * @param url URL
     * @param data Data
     * @param observe Kind of Data Return by Observeable (default is body)
     */
    get(url: string, observe?: string, headerData?: any): Observable<BaseResponse<BaseResponseData>> {      
        return ServiceUtility.getRestService().get(url,observe, headerData);
    }

    /**
     * Post Data (Async)
     *
     * @param url URL
     * @param data Data
     * @param observe Kind of Data Return by Observeable (default is body)
     */
    post(url: string, data: BaseRequest, observe?: string): Observable<BaseResponse<BaseResponseData>> {
        console.log("Laxmi C Jainhttp rest service");
        return ServiceUtility.getRestService().post(url, data);
    }

    /**
     * Put Data (Async)
     *
     * @param url URL
     * @param data Data
     * @param observe Kind of Data Return by Observeable (default is body)
     */
    put(url: string, data: BaseRequest, observe?: string): Observable<BaseResponse<BaseResponseData>> {
        return ServiceUtility.getRestService().put(url, data);
    }

    /**
     * Post Data (Async)
     *
     * @param url URL
     * @param data Data
     * @param observe Kind of Data Return by Observeable (default is body)
     */
    cleanAndPost(url: string, data: BaseRequest, observe?: string): Observable<BaseResponse<BaseResponseData>> {
        console.log("Laxmi C Jainhttp rest service 1clean and post");

        return ServiceUtility.getRestService().cleanAndPost(url, data);
    }
}
