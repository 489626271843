import { Component, OnInit } from '@angular/core';
import { OrganisationDetails } from '../../../../providers/cdm/organisationDetails';
import { CommonService } from '../../../../providers/service/common-services.service';
import { SharedService } from 'src/app/providers/service/shared-services.service';
import {Notes} from '../../../../providers/cdm/organisation';
@Component({
  selector: 'app-notes-board',
  templateUrl: './notes-board.component.html',
  styleUrls: ['./notes-board.component.scss']
})
export class NotesBoardComponent implements OnInit {

  public notice: Notes[];
  constructor(private sharedService: SharedService,private commonService: CommonService) {
    
   }

  ngOnInit() {
    this.getNoticeData();
  }
  /*This function will show and hide notice in click on button */
  showNoticeDetails(notes) {
    if (notes.showDetails) { notes.showDetails = false; }
    else { notes.showDetails = true; }
  }

  openPage(notes):void{
   // const browser = this.iab.create(notes.attachment);
    //browser.show();
  }

  getNoticeData() {
    this.commonService.getNoticeList()
      .subscribe((res) => {
        console.log(" response data notice board" + JSON.stringify(res));
        this.notice= res['notices'];
        console.log(" response data this.notice" + JSON.stringify(this.notice));
      })
  }

  
}
