import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from 'src/app/providers/service/common-services.service';
import { UserProfileService } from 'src/app/providers/service/user-profile.service';
import { LoginService } from '../login.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoggingService } from '../../../../providers/service/logger/logging.service';
import {Errordata} from '../../../../providers/cdm/errordata';

@Component({
  selector: 'app-verifyaccount',
  templateUrl: './verifyaccount.component.html',
  styleUrls: ['./verifyaccount.component.scss']
})
export class VerifyaccountComponent implements OnInit {
  
  verifyForm:FormGroup;
  error: Errordata;
  mobileNumber:Number;
  public errorMessage: string = "";

  constructor(private fb: FormBuilder,
    private router: Router,
    private translateService: TranslateService,
    public loginService: LoginService,
    public userProfileService: UserProfileService,
    public commonService: CommonService,
    public matSnackBar: MatSnackBar,
    public logger: LoggingService
  ) {
    this.mobileNumber = this.userProfileService.mobileNumber;
    this.setFormState();
  }

  ngOnInit() {
   this.mobileNumber = this.userProfileService.mobileNumber;
   
  }

  setFormState() {
    this.verifyForm = this.fb.group({
      'username': [this.mobileNumber, [Validators.required, Validators.minLength(12), Validators.maxLength(12)]],
      'securitycode': [null, [Validators.required, Validators.minLength(6)]],
    });
  }

  /* resend security code again*/
  forgetsecuritycode() {
    // this.showMessage("authPage.sentSecuritycode");
     this.logger.log(this.logger.DEBUG, "VerifyaccountPage:resendSecuritycode");
     this.resendSecurityCode();
   } 
 
    /* This method authenticate users */
  verifyUser() {
    const payload = {
        username: this.mobileNumber,
        securitycode: this.verifyForm.controls.securitycode.value,
        var: "1.0.2"
      };
    this.loginService.verifyUser(payload,this.userProfileService.accessToken)
      .subscribe(res => {
        let response = res as any;        
        this.logger.log(this.logger.DEBUG, "VerifyaccountPage:verifyUser" + response);
        if (response.errors) {
          this.error = response.errors;
          this.logger.logData(this.logger.ERROR, "VerifyaccountPage:verifyUser", response.errors);
          this.showMessage("verifyPage." + response.errors.errorCode);
        }
        if (response.Profile) {
          this.userProfileService.setUserProfile(response);
          this.userProfileService.isLoggedIn = true;
          this.router.navigate(['/dashboard']);
        }
      },
     error => {
        this.logger.logData(this.logger.ERROR, "VerifyaccountPage:verifyUser", error);
        this.error = <any>error;
        this.showMessage("systemError");
      }
      );
  }

  /* This method authenticate users */
  resendSecurityCode() {
    this.loginService.forgetpassword(+this.mobileNumber)
    .subscribe((res) => {
      let response = res as any
      this.logger.log(this.logger.DEBUG, "authPage:resedSecurityCode" + response);
            if (response.errors) {
                this.error = response.errors;
                this.logger.logData(this.logger.ERROR, "authPage:resedSecurityCode", response.errors);
                this.showMessage("authPage." + response.errors.errorCode);
            }
            if (response.token) {
                this.userProfileService.setToken(response.token);
                this.showMessage("authPage.sentSecuritycode");
            };
        }, error => {
            this.logger.logData(this.logger.ERROR, "DepositPage:DepositMoney", error);
            this.error = <any>error;
            this.showMessage("systemError");
        }
        );
}
/* Show Toast Message */
showMessage(displayMessage: string) {
  let durationInSeconds = 5;
  this.translateService.get(displayMessage).subscribe(
    value => {
      this.errorMessage=value;
      setTimeout(() => {
        this.errorMessage = "";
      }, 20000);
      
      this.matSnackBar.open(value, "Close", {
        duration: 2000,
      });
    }
  )
}


}
