/**
 * CommonService
 * 
 * @copyright Digitalpeti Technologies Ltd.
 */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { Observable } from 'rxjs';
import { UserProfileService } from 'src/app/providers/service/user-profile.service';
import { serviceBaseURL_CS } from 'src/environments/environment';
import { BaseResponse } from '../../core/models/response/base-response.model';
import { RestService } from '../../core/services/restful/rest.service';
//import { AlertValueDialogComponent } from '../../common/components/dialog-box/alert-value-dialog.component';
import { DialogBoxComponent } from '../../common/components/dialog-box/dialog-box.component';
import { ResourceFormation } from '../../common/models/common.model';
import { ApplicationConfigurationService } from '../../common/services/application-configuration.service';
import { CommonBaseService } from './common-base.service';
import { OrganisationDetails } from '../cdm/organisationDetails';
//import {Notes} from '../../providers/cdm/organisationDetails';
@Injectable({
    providedIn: 'root'
})
export class UsersOrgService {
    private options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
    constructor(public dialog: MatDialog,
        public http: HttpClient,
        private userProfileService: UserProfileService,
        private applicationConfigurationService: ApplicationConfigurationService,
        private restService: RestService,
        private commonBaseService: CommonBaseService) {

    }

    // showDialogBox
    showDialogBox(type, message) {
        this.dialog.open(DialogBoxComponent, {
            data: { type: type, message: message }
        });
    }

    logError(domain, feature, error) {
        if (this.userProfileService && this.userProfileService.accessToken) {
            this.commonBaseService.error(domain, feature, error).subscribe(respose => {
                console.log(respose);
            });
        }
    }

    getNoticeList()
    {
        return this.http.get("/assets/masterdataconfig/notice.json") as Observable<BaseResponse<OrganisationDetails>>;
      
    }
   
      /***************************deposit organisation into for logged in customer account******************************/
  //http://localhost:8080/api/v1/organisations/deposit
  deposit(userName: number, organisation: number, amount: number, currency: string, purpose: string, schemeName:string, updatedby:number, token: string, hostName: string): Promise<any> {
    let user_org_url = hostName + '/api/v1/user/organisations/';  // URL to web API
    let request = {
      'userName': userName,
      'organisation': organisation,
      'amount': amount,
      'currency': currency,
      'purpose': purpose,
      'schemeName': schemeName,
      'updatedby':updatedby
    }
    let headers = new Headers({ 'Content-Type': 'application/json', 'x-access-token': token });
    return 
    /*this.http.post(user_org_url + 'deposit', request)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);*/
  }

}