/**
 * CommonBaseService
 * @copyright Digitalpeti Technologies Ltd. 2019-20  
 */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseResponse } from 'src/app/core/models/response/base-response.model';
import { UserProfileService } from 'src/app/providers/service/user-profile.service';
import { serviceBaseURL_CS } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CommonBaseService {

    constructor(private http: HttpClient,
        private userProfileService: UserProfileService) {

    }

    /**
     * HTTP ervice call
     */
    error(domain: string, feature: string, error: string): Observable<BaseResponse<any>> {
        let object = {
            'domain': domain,
            'feature': feature,
            'error': error,
            'user': this.userProfileService.getUserProfile()
        }
        //TODO User service if user error need to record in server side
       // return this.http.post(serviceBaseURL_CS + "/weblog " , object) as Observable<BaseResponse<any>>;
       return;
    }
}
