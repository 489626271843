/**
 * Services
 * 
 * @copyright 
 */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { BaseResponse } from 'src/app/core/models/response/base-response.model';
import { RestService } from 'src/app/core/services/restful/rest.service';
import { loginServiceBaseURL, serviceBaseURL_CS, loginPermissionBaseURL } from 'src/environments/environment';
import { UserProfileService } from '../../../providers/service/user-profile.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  loginresponse ='/assets/mocks/user-login-response1.json';
  permissionresponse ='/assets/mocks/userpermissions.json';
  createUserData='/assets/mocks/createUserData.json';
  constructor(private router: Router,
    private translate: TranslateService,
    public userProfileService: UserProfileService,
    private restService: RestService,
    private http: HttpClient) {
  }

  /**
   * This function is used for validating user
   * @param userid password 
   */
  login(reqBody: any) {
    // return this.restService.get(this.loginresponse ) as Observable<BaseResponse<any>>;
    return this.http.post(loginServiceBaseURL, reqBody) as Observable<BaseResponse<any>>;
  }

  getState() {
    // return this.restService.get(this.loginresponse ) as Observable<BaseResponse<any>>;
    return this.http.get("https://digitalpeti.com/in/api/v1/public/country/IN") as Observable<BaseResponse<any>>;
  }

  district(code:string) {
    // return this.restService.get(this.loginresponse ) as Observable<BaseResponse<any>>;
    return this.http.get("https://digitalpeti.com/in/api/v1/public/state/IN/"+code) as Observable<BaseResponse<any>>;
  }

  /***
   * Get permissions
   */
  permission(username) {
    let queryString = "?userId=" + username;
    return this.restService.get(this.permissionresponse ) as Observable<BaseResponse<any>>;
    //return this.http.get(loginPermissionBaseURL + "/acl/userpermissions" + queryString) as Observable<BaseResponse<any>>;
  }

  gethtml() {
    return this.restService.get("http://digitalpeti.com/tnc.html" ) as Observable<BaseResponse<any>>;
    //return this.http.get(loginPermissionBaseURL + "/acl/userpermissions" + queryString) as Observable<BaseResponse<any>>;
  }


  /***
   * Get permissions
   */
  getJson() {
    return this.http.get("https://firebasestorage.googleapis.com/v0/b/nainagiri.appspot.com/o/json%2Fcountry.json?alt=media&token=c192a1f0-f8f1-4df9-ba43-36ace9174c16") as Observable<BaseResponse<any>>;
  }
  
  
  /**************************create profile*********************************************/
  // create user profile using user profile apis
  createUsers(data: any, country:string,language:string,deviceToken:string) {
    // prepare json object for service request
     if(!deviceToken)
     {
       deviceToken="blank";
     }
     var username = data.username;
     let reqBody:any;
     if(data.email && data.email.length>1)
     {
      reqBody = {
       'username' : +username,
       'password' : data.password,
       'email'    : data.email,
       'firstname': data.fullname,
       'lastname' : "",
       'country': country,
       'state': data.state,
       'district': data.district,
       'city'     : data.city,
       'language'     : language,
       'userType':  'M',
       'deviceToken':deviceToken
     };
      } else {
        reqBody = {  
       'username' : +username,
       'password' : data.password,
       'firstname': data.fullname,
       'lastname' : "",
       'country': country,
       'state': data.state,
       'district': data.district,
       'city'     : data.city,
       'language'     : language,
       'userType':  'M',
       'deviceToken':deviceToken      
     };
     }
     console.log("print 3333"+JSON.stringify(reqBody));
     let crete_user_api_url:string = 'https://digitalpeti.com/in/api/v1/public/registration';  // URL to web API
  //return this.http.post(request, crete_user_api_url) as Observable<any>;
     //return this.http.post(loginServiceBaseURL, reqBody) as Observable<BaseResponse<any>>;
      //return this.restService.get(this.createUserData ) as Observable<BaseResponse<any>>;
  
//     return 
     return this.http.post(crete_user_api_url, reqBody) as Observable<BaseResponse<any>>;
 
    }
 
   /**************************reset password*********************************************/
   // reset user password using user profile apis
   resetPassword(data: any,token:string) {     let request = {
      'password' : data.password,
      'securitycode': data.securitycode
     }
     //http://digitalpeti.com/in/api/v1/public/919205730042/resetpassword
     let resetPassword_user_api_url = "https://digitalpeti.com/in"+'/api/v1/public/';
     return this.http.post(resetPassword_user_api_url+data.username+"/resetpassword", request)
   }

 
 
   /**************************verifyUser *********************************************/
   // reset user password using user profile apis
   verifyUser(data: any,token:string){
    console.log("verify print"+JSON.stringify(data)+"token"+token);
     let request:any = {
       'securitycode': data.securitycode,
       'token':token
     };
     console.log("verify print"+JSON.stringify(request));
     let verify_user_api_url:string = "https://digitalpeti.com/in/api/v1/users/"+data.username+"/verify";  // URL to web API
     return this.http.post(verify_user_api_url, request);
   }

  addorg(userId: string,token:string)
  {    
     let request:any = {
       'userName': userId,
       'organisation':910220,
       'fullName':"श्री दिगंबर जैन सिद्धक्षेत्र नैनागिरि",
       'shortName':'नैनागिरि',
       'status':'R' ,
       'token':token
     };
     console.log("add org print"+JSON.stringify(request));
     let org_url:string = "http://digitalpeti.com/in/api/v1/user/organisations";  // URL to web API
     return this.http.post(org_url, request);
  }

    /***************************sendSecurityCode******************************/
  sendSecurityCode(username: number) {
    let forgetPasswordUrl = "https://digitalpeti.com/in/api/v1/public/forgetpassword";
    let request = {
      'username': +username
    }
    return this.http.post(forgetPasswordUrl, request) as Observable<BaseResponse<any>>;
  }

  /***************************forgetpassword******************************/
  forgetpassword(username: number) {
    let forgetPasswordUrl = "https://digitalpeti.com/in" + '/api/v1/public/forgetpassword';
    let request = {
      'username': +username
    }
    return this.http.post(forgetPasswordUrl, request) as Observable<BaseResponse<any>>;
  }



}



