import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdminRoutingModule } from './admin-routing.module';
import { MembersDonationSummaryComponent } from './members-donation-summary/members-donation-summary.component';


@NgModule({
  declarations: [MembersDonationSummaryComponent],
  imports: [
    CommonModule,
    AdminRoutingModule
  ]
})
export class AdminModule { }
