/**
 *  Event Service
 *
 * @copyright Digitalpeti Technologies Ltd. 2017-18
 */
// Angular
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
// Core
import { BaseService } from './base.service';
// import { Message } from '../models/base/message.model';
// import { MessageUtility } from '../common/util/message.utility';

/**
 * Status Message
 */
// export class StatusMessage extends Message {

//     /**
//      * Initialize
//      */
//     constructor(type: string, message: string) {
//         super();
//         //
//         this.type = type;
//         // this.message = NgcMessageUtility.getI18NGlobalValue(message);
//     }
// }

/**
 * Notification Message
 */
// export class NotificationMessage extends Message {
//     public dateTime: Date;

//     /**
//      * Initialize
//      */
//     constructor(type: string, message: string, dateTime: Date) {
//         super();
//         //
//         this.type = type;
//         this.message = message;
//         this.dateTime = dateTime;
//     }
// }

/**
 * Broadcast Event
 */
export class BroadcastEvent {
    public subject: EventSubject;
    public eventId: string;
    public sourceId: string;
    public targetId: string;
    public data: any;
}

/**
 * Job Event
 */
export class JobEvent {
    public subject: string;
    public eventId: string;
    public data: any;
}


/**
 * Event Subject
 */
export enum EventSubject {
    BROADCAST = 1,
    SYSTEM = 2,
    CONFIGURATION = 3,
    NOTIFICATION = 4,
    SYSTEM_NOTIFICATION = 5,
    RESTFUL_NOTIFICATION = 6,
    PAGE_NOTIFICATION = 7,
    CONTROL_NOTIFICATION = 8,
    ROUTER_OUTLET_NOTIFICATION = 9,
    BUSINESS = 10,
    DEBUGGER = 11
}

/**
 * Event Service
 */
@Injectable()
export class EventService extends BaseService {
    private observers: any = {};

    /**
     * Initialize
     */
    constructor() {
        super();
    }

    /**
     * Gets Custom Subject Event Observer
     *
     * @param subject Subject of a Particular Type
     */
    public getJobEventObserver(subject: string): Observable<JobEvent> {
        // If Event Subject doesn't exist, Create One
        if (this.observers[subject] === undefined) {
            const jobSubject = new Subject<JobEvent>();
            const observer = jobSubject.asObservable();
            //
            this.observers[subject] = {
                subject: jobSubject,
                observer
            };
        }
        return this.observers[subject].observer;
    }

    /**
     * Complete Custom Subject
     *
     * @param subject Subject of a Particular Type
     */
    public completeJobEvent(subject: string): void {
        // If Event Subject doesn't exist, Create One
        if (this.observers[subject] !== undefined && this.observers[subject].subject) {
            (this.observers[subject].subject as Subject<JobEvent>).complete();
        }
    }

    /**
     * Gets Standard Event Observer
     *
     * @param subject Subject of a Particular Type
     */
    public getEventObserver(subject: EventSubject): Observable<BroadcastEvent> {
        // If Event Subject doesn't exist, Create One
        if (this.observers[subject] === undefined) {
            const broadcaseSubject = new Subject<BroadcastEvent>();
            const observer = broadcaseSubject.asObservable();
            //
            this.observers[subject] = {
                subject: broadcaseSubject,
                observer
            };
        }
        return this.observers[subject].observer;
    }

    /**
     * Raise Event
     *
     * @param event Event
     */
    public raiseEvent(event: BroadcastEvent): void {
        if (this.observers[event.subject] === undefined) {
            // console.debug("No Event Raised for " + event.subject);
            return;
        }
        this.observers[event.subject].subject.next(event);
    }

    /**
     * Raise Job Event
     *
     * @param event Event
     */
    /*public raiseJobEvent(event: JobEvent): void {
        if (this.observers[event.subject] === undefined) {
            // console.debug("No Event Raised for " + event.subject);
            return;
        }
        this.observers[event.subject].subject.next(event);
    }*/

}
