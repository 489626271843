import { Component, OnInit,ViewChild } from '@angular/core';
import { OrganisationDetails } from '../../../../providers/cdm/organisationDetails';
import { SharedService } from 'src/app/providers/service/shared-services.service';
import { MatTableDataSource } from '@angular/material/table';
import {MatSort} from '@angular/material/sort';

@Component({
  selector: 'app-schemes',
  templateUrl: './schemes.component.html',
  styleUrls: ['./schemes.component.scss']
})
export class SchemesComponent implements OnInit {

  organisations: OrganisationDetails;
  dataSource:any;
  constructor(private sharedService: SharedService) {
    this.organisations = this.sharedService.organisation;
    this.dataSource=this.organisations.schemes;
   }

   @ViewChild(MatSort, {static: true}) sort: MatSort;
  ngOnInit() {
  }

  displayedColumns: string[] = ['amount' ,'name'];

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    console.log(filterValue);
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


}
