/**
 * Component
 * 
 * @copyright Digitalpeti Technologies Ltd.
 */
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from 'src/app/providers/service/common-services.service';
import { SharedService } from 'src/app/providers/service/shared-services.service';
import { UserProfileService } from 'src/app/providers/service/user-profile.service';
import { LoginService } from '../login.service';
import { ApplicationConfigurationService } from '../../../../common/services/application-configuration.service';
import {Profiles, Profile} from 'src/app/providers/cdm/country';
@Component({
  selector: 'app-login-home',
  templateUrl: './login-home.component.html',
  styleUrls: ['./login-home.component.scss']
})

export class LoginHomeComponent implements OnInit {
  public loginForm: FormGroup;
  public loginForm1: FormGroup;
  private lang: string = 'en';
  public errorMessage: string = "";
  transLanguage: string = 'English';
  language: string = '';
  jsonData:any={};
  public mobileNumber: Number;
  public showlogin: boolean=false;

  constructor(private router: Router, private fb: FormBuilder,
    private sanitizer: DomSanitizer,
    private translateService: TranslateService,
    public loginService: LoginService,
    public userProfileService: UserProfileService,
    public commonService: CommonService,
    public sharedService: SharedService,
   

    private applicationConfigurationService: ApplicationConfigurationService,) {
    this.showlogin = this.userProfileService.isShowlogin;
    translateService.addLangs(['en', 'hi']);
    this.language = sharedService.getSelectedLanguage();
    if (!this.language || this.language === '') {
      this.language = this.lang;
      translateService.setDefaultLang(this.lang);
      const browserLang = translateService.getBrowserLang();
      translateService.use(browserLang.match(/en|hi/) ? browserLang : this.lang);
      sharedService.setSelectedLanguage(browserLang.match(/en|hi/) ? browserLang : this.lang);
    } else {
      translateService.setDefaultLang(this.language);
      translateService.use(this.language);
      sharedService.setSelectedLanguage(this.language);
    }

    if (this.language == 'hi') {
      this.transLanguage = 'हिन्दी';
      this.lang = 'hi';
    } else {
      this.transLanguage = 'English';
      this.lang = 'en';
    }
    this.showlogin=sharedService.getshowlogin();
    this.getOrgData();
    //this.getJsonData();
  }

  //Intitialise the login form 
  ngOnInit() {
   // let profile: any;
    if (localStorage.getItem("digitalpetiProfile")) {
      let profile:Profiles = JSON.parse(localStorage.getItem("digitalpetiProfile"));
      
      console.log("profile11*****"+profile);
      if (profile) {
        console.log("profile12*****"+profile);
        this.userProfileService.setUserProfile(profile);
        this.accessPermissions(profile);
      }
    }else
    {

    let msg = '';
    if (localStorage.getItem("sessionExpiredMesg")) {
      msg = localStorage.getItem("sessionExpiredMesg");
      if (msg != '') {
        this.commonService.showDialogBox('E', msg);
      }
    }
    localStorage.setItem("sessionExpiredMesg", '');
    this.initLoginForm();
  }
}

  initLoginForm() {
    this.loginForm = this.fb.group({
      'username': [null, Validators.compose([Validators.minLength(10), Validators.maxLength(10)])],
      'password': [null, Validators.compose([Validators.required, Validators.minLength(6)])],
    });
    this.loginForm1 = this.fb.group({
      'username': [null, Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(10)])],
      language: [this.language],
    });
  }

  setLanguage() {
    let language = this.loginForm.value.language;
    this.translateService.use(language);
    if (language == 'hi') {
      this.transLanguage = 'हिन्दी';
      this.lang = 'hi';
    } else {
      this.transLanguage = 'English';
      this.lang = 'en';
    }
    this.sharedService.setSelectedLanguage(this.lang);
  }

  login() {
    if (this.loginForm.valid) {
      const payload = {
        username: this.mobileNumber,
        password: this.loginForm.controls.password.value,
        var: "1.0.2"
      };
      this.loginService.login(payload).subscribe((response) => {

        let loginResponse = response as any;
        console.log("***LC"+JSON.stringify(loginResponse));
        if(loginResponse.errors)
        {
          this.errorMessage=loginResponse.errors.errorMessage;
        }else
        {
         localStorage.setItem("digitalpetiProfile",JSON.stringify(loginResponse));
        this.userProfileService.setUserProfile(loginResponse);
        this.addorg(loginResponse.Profile.userId,loginResponse.Token);
         console.log("verification code"+loginResponse.Profile.verificationRequired);
        if (loginResponse.Profile && loginResponse.Profile.verificationRequired) {
          console.log("verification code"+loginResponse.Profile.verificationRequired);
          this.router.navigate(['/VerifyaccountPage']);
        }else
        {
          this.accessPermissions(loginResponse);
          
        }
        
        /*if (loginResponse.verificationRequired) {
          this.commonService.getMenus().subscribe((res) => {
            this.applicationConfigurationService['MENU_LIST'] = res['MENU_LIST'];
          });
          this.accessPermissions(loginResponse);
        }*/
      }
      }, (err: HttpErrorResponse) => {
        // this.errorMessage = this.translate.translations[this.lang].errors.LOGIN_FAILED_ERROR_MESSAGE;
        this.errorMessage = err.toString();
        this.commonService.logError('Login', 'Login', err);
        setTimeout(() => {
          this.errorMessage = "";
        }, 20000);
      });
    }
  }

  login1() {
    if (this.loginForm1.valid) {
      const payload = {
        username: 910000000000 + Number(this.loginForm1.controls.username.value),
        password: "test@1234",
        var: "1.0.2"
      };
      this.loginService.login(payload).subscribe((response) => {
        let loginResponse = response as any
        if (loginResponse) {
          this.userProfileService.setusername(payload.username);
          if (loginResponse.errors.errorCode == 101) {
            this.mobileNumber = payload.username;
          }
          if (loginResponse.errors.errorCode == 102) {
            this.mobileNumber = this.loginForm1.controls.username.value;
            this.register();
          }
          console.log(loginResponse.errors.errorCode);
        }
      }, (err: HttpErrorResponse) => {
        // this.errorMessage = this.translate.translations[this.lang].errors.LOGIN_FAILED_ERROR_MESSAGE;
        this.errorMessage = err.toString();
        this.commonService.logError('Login', 'Login', err);
        setTimeout(() => {
          this.errorMessage = "";
        }, 20000);
        //this.commonService.logError('Login', 'Login', err);
      });
    }
  }

  addorg(userId:string, token:string)
  {
    console.log("@@@@ add organisation"+userId+token)
    this.loginService.addorg(userId,token).subscribe((response) => { 
    }, (err: HttpErrorResponse) => {     
    });
  }

  getJsonData()
  {
    this.loginService.getJson().subscribe((response) => {
      this.jsonData=JSON.stringify(response);
    console.log("JSONData"+response);
    })
  }
  accessPermissions(loginResponse) {
    console.log("accessPermissions");
    this.loginService.permission(loginResponse.Profile.userId).subscribe((response) => {
      let userPermissions = response as any
      if (userPermissions) {
        console.log("accessPermissions");
        // this.userProfileService.setUserProfile(loginResponse);
        this.userProfileService.setUserAcess(userPermissions);
        this.userProfileService.isLoggedIn = true;
        if (userPermissions.Profile && userPermissions.Profile.verificationRequired) {
          this.router.navigate(['/VerifyaccountPage']);
        }
        else {
          console.log("go to dashboard");
          this.router.navigate(['/dashboard']);
        }
      }
    }, (err: HttpErrorResponse) => {
      this.errorMessage = 'Authentication passed. Internal Server Error while fetching user permissions';
      this.commonService.logError('Login', 'AccessPermissions', err);
      setTimeout(() => {
        this.errorMessage = "";
      }, 20000);

    });
  }

    getOrgData() {
    this.commonService.getOrganisationList()
      .subscribe((res) => {
       // console.log(" response data" + JSON.stringify(res));
        this.sharedService.organisation = res['organisation'];
       // console.log(" response data11" + this.sharedService.organisation);
      })
  }

  //open forgot password popup
  openForgotPopup() {
    this.resetpassword1();
  }

  /* forgetpassword  button */
  resetpassword1() {
    if (this.loginForm.controls['username'].valid) {
      this.router.navigate(['/forgetpassword']);
    } else {
      //this.showMessage('loginPage.invalidUserNameAndOrgId');
    }
  }

  register() {
    this.router.navigate(['/register']);
  }
  
  facebook(){
	  location.href = "https://www.facebook.com/NainagiriJi";
  }
}

