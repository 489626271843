import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SharedService } from './providers/service/shared-services.service';

@Injectable({providedIn: 'root'})
export class ConfigurationService {
    private readonly CONFIG_URL = 'assets/config/config.json';
    private configuration$: Observable<any>;
    private configuration: any;

    private configurationSource = new BehaviorSubject("");
    configurationData = this.configurationSource.asObservable();

    constructor(private http: HttpClient,
        public sharedService: SharedService) {
    }

    loadConfigurationData(): any {
        if (!this.configuration$) {
            this.http.get<any>(this.CONFIG_URL).subscribe(response => {
                this.configuration$ = response;
                this.configuration = response;
                this.configurationSource.next(response);
                this.sharedService.setConfigurationData(response);
                // this.commonService.setApplicationConfigurationData(response);
            });
        }
        // return this.configuration$;
    }

    getConfigurationData() {
        return this.configuration;
    }
}