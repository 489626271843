import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgGridModule } from 'ag-grid-angular';

import { LoginRoutingModule } from './login-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import {LoggingService} from '../../../providers/service/logger/logging.service';
import {LoginService} from'../login/login.service';
import { DashboardComponent } from '../login/dashboard/dashboard.component';
import { LoginHomeComponent } from '../login/login-home/login-home.component';
import { RegistrationHomeComponent } from '../login/registration-home/registration-home.component';
import { ResetpasswordComponent } from "../login/resetpassword/resetpassword.component";
import { VerifyaccountComponent } from "../login/verifyaccount/verifyaccount.component";
import { MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
  declarations: [LoginHomeComponent,
    DashboardComponent,
    RegistrationHomeComponent,
    ResetpasswordComponent,
    VerifyaccountComponent],
  imports: [
    CommonModule,
    LoginRoutingModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    SharedModule,
    TranslateModule,
    AgGridModule.withComponents([])     
  ],providers: [LoggingService,LoginService
  ]
})
export class LoginModule { }
