import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material.module';
import { HHMMPipe } from '../common/pipes/hhmm-pipe';
import { CommonFeatureComponent } from '../common/components/common-feature/common-feature.component';
import { RemoveSeconds } from '../common/pipes/remove-seconds-pipe';
import { HasMasterPermissionDirective } from '../core/directive/has-master-permission.directive';
import { ConfirmPopupComponent } from './../common/components/dialog-box/confirmation/confirmation.component';

@NgModule({
  declarations: [CommonFeatureComponent, ConfirmPopupComponent, HHMMPipe, RemoveSeconds,HasMasterPermissionDirective],
  imports: [
    CommonModule,
    MaterialModule    
  ],
  exports: [
    MaterialModule,
    CommonFeatureComponent,
    HHMMPipe,
    RemoveSeconds,
    HasMasterPermissionDirective,
    ConfirmPopupComponent
  ],
  entryComponents:[ConfirmPopupComponent
		]
})
export class SharedModule { }
