/**
 * Component
 *
 * @copyright
 */
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { FirebaseService } from '../../../../providers/service/firebase.service';
import {member} from '../../members-list/member';
import {MAT_DIALOG_DATA} from '@angular/material';
import { Inject } from '@angular/core';
@Component({
  selector: 'app-member-profile',
  templateUrl: './member-profile.component.html',
  styleUrls: ['./member-profile.component.scss']
})
export class MemberDialogProfileComponent implements OnInit {
  registerForm: FormGroup;
  public defaultImage="https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcTG_3OWJBgJNmblsZ6AZmqq4rglXd3D_HSKpJq0Z_kKP26fmBkS&usqp=CAU";
  registeredCustomer:any;
  members:member[]=[];
  cusId:string;
  id:string;
  isDialog:boolean=false;
  constructor(
    public firebaseService: FirebaseService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if(data)
    {
      this.cusId=data.cusId;
    }
    this.getCustomer(this.cusId);
  }

  ngOnInit() {
  }

  getCustomer(data:string) {
    this.firebaseService.getCustomer(data).subscribe(
      (response)=>{
        response.forEach(element => {
          let member:any=element.payload.doc.data();
          //member.id=element.payload.doc.id;
          this.id=element.payload.doc.id;
          this.members=[];
          this.members.push(member);
          if(member.imageURL && member.imageURL!="")
          {
           this.defaultImage = member.imageURL;
         }
          console.log(element.payload.doc.data());
        });
          this.registeredCustomer=this.members[0];
      },
      error=>{
        console.log(error.status);
      }
    )
  }

}
