import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MembersComponent } from './members/members.component';
import { NotesBoardComponent } from './notes-board/notes-board.component';
import { SchemesComponent } from './schemes/schemes.component';
import { SponserComponent } from './sponser/sponser.component';
import { SummaryComponent } from './summary/summary.component';
import {BankComponent} from './bank/bank.component'
import {OrgRoutingModule } from './organisation-details-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [BankComponent, MembersComponent, NotesBoardComponent, SchemesComponent, SponserComponent, SummaryComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    OrgRoutingModule,
    TranslateModule.forChild()
  ], exports: [
    SharedModule
], providers: [
],
entryComponents: [
]
})
export class OrganisationDetailsModule { }   
