/**
 * SharedService
 * 
 * @copyright Digitalpeti Technologies Ltd.
 */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NGXLogger } from 'ngx-logger';
import { DialogBoxComponent } from '../../common/components/dialog-box/dialog-box.component';
import { MatDialog } from '@angular/material';
import { ResourceFormation } from '../../common/models/common.model';
import { TranslateService } from '@ngx-translate/core';
import { OrganisationDetails } from '../cdm/organisationDetails';

@Injectable({
    providedIn: 'root'
})
export class SharedService {
    public selectedLangCode: string;
    public selectedTimezone: string = 'Asia/Hong_Kong'; // TODO Digitalpeti  this value should come from application.config file
    userSearchFormData: any[] = [];
    lastAccessTime: any;
    defaultSessionTimeout: any;
    configurationData: any = {};
    organisation:OrganisationDetails;
    public showlogin:boolean=false;
    constructor(public http: HttpClient,
        private logger: NGXLogger,
        public dialog: MatDialog,
        public translateService: TranslateService) {
    }

    getSelectedLanguage(): string {
        return "en";
        //return this.selectedLangCode;
    }

    getshowlogin(): boolean {
       return this.showlogin;
    }

    setshowlogin(status:boolean): boolean {
        return this.showlogin=status;
     }

    setSelectedLanguage(code: string) {
        this.selectedLangCode = code;
    }

    getSelectedTimezone(): string {
        return this.selectedTimezone;
    }

    setSelectedTimezone(timeZone: string) {
        this.selectedTimezone = timeZone;
    }

    getCurrentDateTime(): string {
        let datetime = new Date().toLocaleString('en-US', { timeZone: this.selectedTimezone });
        return datetime;
    }

    time(): number {
        let datetime = (new Date().toLocaleString('en-US', { timeZone: this.selectedTimezone }));
        return  (new Date(datetime)).getTime();       
    }

    hour(): number {
        let datetime = (new Date().toLocaleString('en-US', { timeZone: this.selectedTimezone }));
        return  (new Date(datetime)).getHours();       
    }


    date(): Date {
        let datetime = new Date().toLocaleString('en-US', { timeZone: this.selectedTimezone });
        return new Date(datetime);
    }
    now(): string {
        //let city = this.selectedTimezone;
        let datetime = new Date().toLocaleString('en-US', { timeZone: this.selectedTimezone });
        return datetime;
    }

    getZoneDate(date): Date {
        let datetime = new Date(date).toLocaleString('en-US', { timeZone: this.selectedTimezone });
        return new Date(datetime);
    }
    
  

    // To Show popup message
    openPopup(type, message) {
        this.dialog.open(DialogBoxComponent, {
            data: { type: type, message: message }
        });
    }

    public static getTaskTeamStructure(staffPositionCodeDetails) {
        let resourceFormation: ResourceFormation[] = this.getResourceFormation(staffPositionCodeDetails);
           
        return {};
    }

    public static getResourceFormation(staffPositionCodeDetails) {
        let resourceFormation: ResourceFormation[] = [];        
        return resourceFormation;
    }

    getPreTaskTime(engagementStandardList, flightCarrier, flightAircraftTypeGroup, flightType) {
        let preTaskTime: number = 20;     
        return preTaskTime
    }

    getPostTaskTime(engagementStandardList, flightCarrier, flightAircraftTypeGroup, flightType) {
        let postTaskTime: number = 30;       
        return postTaskTime;
    }

    //Translate the text into current selected language
    translate(textKey) {
        //this.translateService.use(this.selectedLangCode);
        let lang = this.getSelectedLanguage();
        return this.translateService.translations[lang].textKey;
        //return this.translateService.instant(textKey);
    }

    setUserSearchForm(key, data) {
        this.userSearchFormData[key] = data;
    }

    getUserSearchForm(key) {
        return this.userSearchFormData[key];
    }

    setLastAccessTime(value) {
        this.lastAccessTime = value;
    }

    getLastAccessTime() {
        return this.lastAccessTime;
    }

    setDefaultSessionTimeout(value) {
        this.defaultSessionTimeout = value;
    }

    getDefaultSessionTimeout() {
        return this.defaultSessionTimeout;
    }

    setConfigurationData(configData) {
        this.configurationData = configData;
    }

    getConfigurationData() {
        return this.configurationData;
    }
}