/**
 *  HTTP Service
 *
 * @copyright Digitalpeti Technologies Ltd. 2017-18
 */
// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpEvent } from '@angular/common/http';
import { Observable, EMPTY } from 'rxjs';
// Core
import { BaseService } from '../base.service';
import { HTTPObserveType } from '../../models/base/http-observe-type.model';
import { ApplicationConfigurationService } from '../../../common/services/application-configuration.service';
import { SharedDataCacheService } from '../../services/shared-data-cache.service';
import { retry, catchError } from 'rxjs/operators';
//

/**
 * New HTTP Serivice Factory
 *
 * @param httpClient HTTP Client
 */
export const NewHTTPServiceFactory = (httpClient: HttpClient) => {
    return new NewHTTPService(httpClient);
};

/**
 *  HTTP Service
 */
@Injectable()
export class NewHTTPService extends BaseService {

    /**
     * Initialize
     */
    constructor(private http: HttpClient,
        private applicationConfigurationService?:ApplicationConfigurationService) {
        super();
        // console.debug("Creating New HTTP Service...!");
    }

    /**
     * Gets Data (Async)
     *
     * @param url URL
     * @param contentType Content Type
     * @param data Data
     * @param observe Kind of Data Return by Observeable (default is body)
     */
    public get(url: string, contentType: string, observe?: string, headerData? : any): Observable<HttpEvent<any>> {
        // Disable With Credentials?
        let disableWithCredentials: boolean;
        if (this.applicationConfigurationService) {
            SharedDataCacheService.getProperty(this.applicationConfigurationService.DISABLE_WITH_CREDENTIALS);
        }
         
        // If Parameter Data Exists
        // if (data) {
        //     const parameterNames: string[] = Object.keys(data);
        //     // Append Each Parameter
        //     parameterNames.forEach((parameterName: string) => {
        //         params.append(parameterName, data[parameterName]);
        //     });
        // }
        // Send HTTP Get Request
        if (observe === HTTPObserveType.RESPONSE) {
            return  this.http.get<HttpEvent<any>>(url, headerData) as Observable<HttpEvent<any>>;
            // .pipe(
            //     retry(1),
            //     catchError(() => {
            //       return EMPTY;
            //     })
            //   );
        } else {
            return  this.http.get<HttpEvent<any>>(url
                // , {
                // headers: { 'Content-Type': contentType },
                // params,
                // reportProgress: true,
                // withCredentials
            // }
            ) as Observable<HttpEvent<any>>;
            // .pipe(
            //     retry(1),
            //     catchError(() => {
            //       return EMPTY;
            //     })
            //   );
        }
    }

    /**
     * Post Data (Async)
     *
     * @param url URL
     * @param contentType Content Type
     * @param data Data
     * @param observe Kind of Data Return by Observeable (default is body)
     */
    public post(url: string, contentType: string, data: any, observe?: string): Observable<HttpEvent<any>> {
        // Disable With Credentials?
        console.log("Laxmi C Jainhttp service post");
        let disableWithCredentials: boolean;
        if (this.applicationConfigurationService) {
            disableWithCredentials  = SharedDataCacheService.getProperty(this.applicationConfigurationService.DISABLE_WITH_CREDENTIALS);
        }
        const withCredentials = disableWithCredentials ? false : true;
        // Send HTTP Post Request
        if (observe === HTTPObserveType.RESPONSE) {
            return  this.http.post(url, data, {
                headers: { 'Content-Type': contentType },
                reportProgress: true,
                withCredentials,
                observe: "response"
            }) as Observable<HttpEvent<any>>;
        } else {
            return  this.http.post(url, data, {
                headers: { 'Content-Type': contentType },
                reportProgress: true,
                withCredentials
            }) as Observable<HttpEvent<any>>;
        }
    }

    /**
     * Put Data (Async)
     *
     * @param url URL
     * @param contentType Content Type
     * @param data Data
     * @param observe Kind of Data Return by Observeable (default is body)
     */
    public put(url: string, contentType: string, data: any, observe?: string): Observable<HttpEvent<any>> {
        // Disable With Credentials?
        let disableWithCredentials: boolean;
        if (this.applicationConfigurationService) {
            disableWithCredentials  = SharedDataCacheService.getProperty(this.applicationConfigurationService.DISABLE_WITH_CREDENTIALS);
        }
        const withCredentials = disableWithCredentials ? false : true;
        // Send HTTP Post Request
        if (observe === HTTPObserveType.RESPONSE) {
            return  this.http.put(url, data, {
                headers: { 'Content-Type': contentType },
                reportProgress: true,
                withCredentials,
                observe: "response"
            }) as Observable<HttpEvent<any>>;
        } else {
            return  this.http.put(url, data, {
                headers: { 'Content-Type': contentType },
                reportProgress: true,
                withCredentials
            }) as Observable<HttpEvent<any>>;
        }
    }
}
