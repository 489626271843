/**
 * Component
 * 
 * @copyright Digitalpeti Technologies Ltd.
 */
import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { UserProfileService } from 'src/app/providers/service/user-profile.service';
import { CommonService } from './providers/service/common-services.service';
import { ApplicationConfigurationService } from './common/services/application-configuration.service';
import { MatDialog } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    title = 'SDJGS-DELHI';
    isLogin: any = false;
    scrollPosition: any = 0;

    constructor(public userProfileService: UserProfileService,
        private router: Router,
        private commonService: CommonService,
        private applicationConfigurationService: ApplicationConfigurationService,
        private translateService:TranslateService
    ) {
        this.isLogin = this.userProfileService.isLoggedIn;
    }

    logoutEvent($event) {
        this.userProfileService.logOut();
    }

    //Intitialise the login form 
    ngOnInit() {
        this.commonService.getSystemParams().subscribe((res) => {
            Object.assign(this.applicationConfigurationService, res);
        });

        this.translateService.setDefaultLang("en");
        this.commonService.getMenus().subscribe((res) => {
            this.applicationConfigurationService['MENU_LIST'] = res['MENU_LIST'];
        });
        //this.initLoginForm();
    }

    @HostListener('window:beforeunload', ['$event'])
    unloadNotification($event: any) {
        if (this.isLogin) {
            $event.returnValue = true;
        } else {
            $event.returnValue = false;
        }
    }

    @HostListener('window:scroll', ['$event'])
    trackScroll(event) {
        this.applicationConfigurationService.currScrollPosition = window.pageYOffset;
    }
}
