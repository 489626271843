import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireDatabase} from '@angular/fire/database';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AnimationStyleMetadata } from '@angular/animations';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  a:any;
  constructor(public db: AngularFirestore, private database: AngularFireDatabase, private http:HttpClient) {}

  getCustomers(){
    return this.db.collection('customers').snapshotChanges();
  }

  getCustomer(searchValue:string){
   return this.db.collection('customers',ref => ref.where('cusId', '==', searchValue)).snapshotChanges()
   }

   createRow(value){
     return this.db.collection('customers').add({
      cusId:value.cusId,
      fullName:value.fullName,
      contact:value.contact,
      state:value.state,
      district:value.district,
      city:value.city,
      email:value.email,
      socialMedia:value.socialMedia,
      profession:value.profession,
      education:value.education,
      family:value.family,
      hobby:value.hobby,
      imageURL:value.imageURL
     });
   }


   getCustomer1(userKey){
     return this.db.collection('customers').doc(userKey).snapshotChanges();
   }
  
  updateCustomer(userKey, value){
    //value.nameToSearch = value.name.toLowerCase();
    console.log("update customer");
    return this.db.collection('customers').doc(userKey).set({
      cusId:value.cusId,
      fullName:value.fullName,
      contact:value.contact,
      state:value.state,
      district:value.district,
      city:value.city,
      email:value.email,
      socialMedia:value.socialMedia,
      profession:value.profession,
      education:value.education,
      family:value.family,
      hobby:value.hobby,
      imageURL:value.imageURL
     });
   }
  
   deleteCustomer(userKey){
    return this.db.collection('customers').doc(userKey).delete();
   }

   loadGullakData(userKey){
    //  let userKey:string= data.toString();
    return this.db.collection('gullakData').doc(userKey).snapshotChanges();
    }

    createGullakData(data:any){  
      let userKey= data.userId.toString();
      const userRef: AngularFirestoreDocument<any> = this.db.doc(`gullakData/${userKey}`);
      const gullakData: any = {
        uid: userKey,
        lastTransactionAmount: data.lastTransactionAmount,
        totalAmount: data.TotalAmount,
        lastTransactionDate: data.lastTransactionDate,
        mobileNumber:data.mobileNumber,
        userName:data.userName,
        city:data.city
      }
      return userRef.set(gullakData, {
        merge: true
      })
    }

     updateGullakData(data:any){
      let userKey= data.userId.toString();
      return this.db.collection('gullakData').doc(userKey).set({
        lastTransactionAmount: data.lastTransactionAmount,
        totalAmount: data.TotalAmount,
        lastTransactionDate: data.lastTransactionDate
      },
      {
        merge:true
      });
     }
    
  //
  // getUsers(){
  //   return this.db.collection('users').snapshotChanges();
  // }
  //
  // searchUsers(searchValue){
  //   return this.db.collection('customers',ref => ref.where('cusId', '>=', searchValue)
  //     .where('cusId', '<=', searchValue + '\uf8ff'))
  //     .snapshotChanges()
  // }
  //
  // // searchUsers(searchValue){
  // //   return this.db.collection('customers',searchValue)
  // //     .snapshotChanges()
  // // }
  //
  // searchUsersByAge(value){
  //   return this.db.collection('users',ref => ref.orderBy('age').startAt(value)).snapshotChanges();
  // }
}
