/**
 * Component
 * 
 * @copyright 
 */
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild, ElementRef,ChangeDetectorRef,AfterContentChecked } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from 'src/app/providers/service/common-services.service';
import { UserProfileService } from 'src/app/providers/service/user-profile.service';
import { LoginService } from '../login.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoggingService } from '../../../../providers/service/logger/logging.service';
import {Errordata} from '../../../../providers/cdm/errordata';

@Component({
  selector: 'app-registration-home',
  templateUrl: './registration-home.component.html',
  styleUrls: ['./registration-home.component.scss']
})
export class RegistrationHomeComponent implements OnInit {
  registerForm: FormGroup;
  states: any;
  districts: any;
  mobileNumber: Number;
  error: Errordata;

  constructor(private fb: FormBuilder,
    private router: Router,
    private translateService: TranslateService,
    public loginService: LoginService,
    public userProfileService: UserProfileService,
    public commonService: CommonService,
    public matSnackBar: MatSnackBar,
    public logger: LoggingService,
    private changeDetector: ChangeDetectorRef
  ) {
   // this.mobileNumber = this.userProfileService.mobileNumber;
    console.log("mobile"+this.mobileNumber);
    if(!this.userProfileService.mobileNumber || this.userProfileService.mobileNumber.toString().length <10)
    {
      this.userProfileService.isShowlogin=true;
      this.router.navigate(['/login']);
    }
    this.getStates("IN");   
  }
  
  ngOnInit() {
    this.setFormState();
    this.mobileNumber = this.userProfileService.mobileNumber;
    
    console.log("mobile"+this.mobileNumber);
  
  }

  setFormState() {
    this.registerForm = this.fb.group({
      // 'orgid': [null, [Validators.required, Validators.minLength(6), Validators.maxLength(6)]],
      'username': [this.mobileNumber, [Validators.minLength(12), Validators.maxLength(12)]],
      'password': [null, [Validators.required, Validators.minLength(6), Validators.maxLength(25)]],
      'email': [null, [ Validators.email]],
      'fullname': [null, [Validators.required, Validators.minLength(6)]],
      'state': [null, ([Validators.required])],
      'district': [null, ([Validators.required])],
      'city': [null, [Validators.required, Validators.minLength(3)]],
    });
  }

  submitForm() {
    let value: any = this.registerForm.value;
    value.username=this.mobileNumber;
    this.loginService.createUsers(value, "IN", "EN", "").subscribe((res) => {
      console.log("register service" + JSON.stringify(res));
      let response = res as any
      if (response) {
        if (response.errors) {
          this.logger.log(this.logger.DEBUG, "RegistrationPage:registerUser" + response);
          this.error = response.errors;
          this.showMessage("registrationPage." + response.errors.errorCode);
        }
        if (response.Token) {
          this.showMessage("registrationPage.registerSuccessfull");
          this.userProfileService.setUserProfile(response);
          this.addorg(response.Profile.userId,response.Token);
          this.router.navigate(['/VerifyaccountPage']);
        }
      }
    }, (err: HttpErrorResponse) => {
      this.logger.logData(this.logger.ERROR, "RegistrationPage:registerUser", err);
      this.error = <any>err;
      this.showMessage("systemError");
    });
  }

  addorg(userId:string, token:string)
  {
    this.loginService.addorg(userId,token).subscribe((response) => { 
    }, (err: HttpErrorResponse) => {     
    });
  }
  
  /* Show Toast Message */
  showMessage(displayMessage: string) {
    let durationInSeconds = 5;
    this.translateService.get(displayMessage).subscribe(
      value => {
        this.matSnackBar.open(value, "Close", {
          duration: 2000,
        });
      }
    )
  }

  /* get state data */
  selectState() {
    this.getDistricts(this.registerForm.controls['state'].value)
  }
  getStates(code) {
    this.loginService.getState().subscribe(response => {
      let res = response as any;
      if (res.errors) {
        this.logger.log(this.logger.DEBUG, "RegistrationPage:registerUser" + res);
        this.error = res.errors;
        this.showMessage("registrationPage." + res.errors.errorCode);
      } else {
        this.states = res;
      }
    },
      error => {
        this.logger.logData(this.logger.ERROR, "RegistrationPage:registerUser", error);
        this.error = <any>error;
        this.showMessage("systemError");
      }
    );
  }
  /* This method get list of district based on state code */
  getDistricts(code) {
    console.log("get district" + code);
    this.loginService.district(code).subscribe(response => {
      let res = response as any;
      console.log("district" + res);
      if (res.errors) {
        this.logger.log(this.logger.DEBUG, "RegistrationPage:registerUser" + res);
        this.error = res.errors;
        this.showMessage("registrationPage." + res.errors.errorCode);
      } else {
        console.log("district" + res);
        this.districts = res;
      }
    },
      error => {
        this.logger.logData(this.logger.ERROR, "RegistrationPage:registerUser", error);
        this.error = <any>error;
        this.showMessage("systemError");
      }
    );
  }
}

