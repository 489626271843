// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    /*apiKey: "AIzaSyBMOaRr0NirNOX-eh2ITJBHUpL3ow8Hr4w",
    authDomain: "splendid-ground-95214.firebaseapp.com",
    databaseURL: "https://splendid-ground-95214.firebaseio.com",
    projectId: "splendid-ground-95214",
    storageBucket: "",
    messagingSenderId: "429226112331"*/
    /*
    sdjgs account
    apiKey: "AIzaSyA1ublb4B8s8-gDlpKzn76ExQIZiB8VKC4",
    authDomain: "digitalpeti.firebaseapp.com",
    databaseURL: "https://digitalpeti.firebaseio.com",
    projectId: "digitalpeti",
    storageBucket: "digitalpeti.appspot.com",
    messagingSenderId: "329693569485",
    appId: "1:329693569485:web:e29d73d165c84581192484",
    measurementId: "G-8616FN6FVX"*/

    apiKey: "AIzaSyAXehF6roJOdYwUTw7Fn9IIATJNreZlm-Q",
    authDomain: "customerdata-c6352.firebaseapp.com",
    databaseURL: "https://customerdata-c6352.firebaseio.com",
    projectId: "customerdata-c6352",
    storageBucket: "customerdata-c6352.appspot.com",
    messagingSenderId: "891082075575",
    appId: "1:891082075575:web:573438a3cdf3bf7b804c32",
    measurementId: "G-3Y6DN0L48W"
  }
};

export const color_schemes = ["#e24c02", "#fdad33", "#687ef7", "#ffea4f"];

export const color_scheme              = "#e24c02"; // Production
export const serviceBaseURL            = ""; //Staff
export const serviceBaseURL_CS         = ""; //Common
export const loginServiceBaseURL       = "https://digitalpeti.com/in/api/v1/public/auth";  //Login
export const loginPermissionBaseURL    = "";  //Login


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
