import { Component, OnInit } from '@angular/core';
import { OrganisationDetails } from '../../../../providers/cdm/organisationDetails';
import { SharedService } from 'src/app/providers/service/shared-services.service';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnInit {

  organisations: OrganisationDetails;
  constructor(private sharedService: SharedService) {
    this.organisations = this.sharedService.organisation;
   }
  ngOnInit() {
  }

}
