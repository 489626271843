/**
 * Component
 *
 * @copyright
 */
import { Component, OnInit } from '@angular/core';
import { UserProfileService } from 'src/app/providers/service/user-profile.service';
//import { CommonService } from '../../common/services/common-services.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from 'src/app/providers/service/shared-services.service';


import { MatDialog } from '@angular/material';
//import { AvatarDialogComponent } from "../avatar-dialog/avatar-dialog.component";
import { Router } from '@angular/router';
import { FirebaseService } from '../../../providers/service/firebase.service';
import {UploadTaskComponent} from "../../../common/components/upload-task/upload-task.component";
import {member} from '../members-list/member';
import {MAT_DIALOG_DATA} from '@angular/material';
import { Inject } from '@angular/core';
import { LoginService } from '../../../domain/digitalpeti/login/login.service';
@Component({
  selector: 'app-member-profile',
  templateUrl: './member-profile.component.html',
  styleUrls: ['./member-profile.component.scss']
})
export class MemberProfileComponent implements OnInit {
  registerForm: FormGroup;
  public defaultImage="https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcTG_3OWJBgJNmblsZ6AZmqq4rglXd3D_HSKpJq0Z_kKP26fmBkS&usqp=CAU";
  private customerFlag;
  private editFlag=0;
  registeredCustomer:any;
  members:member[]=[];
  cusId:string;
  id:string;
  isDialog:boolean=false;
  states: any;
  districts: any;
  districtName:string;
  stateName:string;
  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    private router: Router,
    public loginService: LoginService,
    public userProfileService: UserProfileService,
    public firebaseService: FirebaseService  
  ) {
    this.cusId=this.userProfileService.userProfile['userId'];
    this.getCustomer(this.cusId); 
    this.getStates("IN",this.userProfileService.userProfile['state']); 
    this.getDistricts( this.userProfileService.userProfile['state'],this.userProfileService.userProfile['district']);

  }

  ngOnInit() {
    this.setFormState();
  }

  setFormState() {
    if(this.editFlag==1){
      this.registerForm = this.fb.group({
        'cusId': [this.registeredCustomer.cusId],
        'fullName': [this.registeredCustomer.fullName, [Validators.required, Validators.minLength(4)]],
        'profession': [this.registeredCustomer.profession, [Validators.required]],
        'education': [this.registeredCustomer.education, [Validators.required]],
        'family': [this.registeredCustomer.family, [Validators.required]],
        'hobby': [this.registeredCustomer.hobby, [Validators.required]],
        'contact': [this.registeredCustomer.contact, [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
        'email': [this.registeredCustomer.email, [ Validators.email]],
        'socialMedia': [this.registeredCustomer.socialMedia],
        'imageURL': [this.registeredCustomer.imageURL],
        'state': [this.registeredCustomer.state],
        'district': [this.registeredCustomer.district],
        'city': [this.registeredCustomer.city]
      });
    }
    if(this.editFlag==0){
      this.registerForm = this.fb.group({
        'cusId': [this.cusId],
        'fullName': [this.userProfileService.userProfile['userName'], [Validators.required, Validators.minLength(4)]],
        'profession': ['', [Validators.required]],
        'education': ['', [Validators.required]],
        'family': ['', [Validators.required]],
        'hobby': [null, [Validators.required]],
        'contact': [this.userProfileService.userProfile['mobileNumber'], [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
        'email': [this.userProfileService.userProfile['emailAddress'], [ Validators.email]],
        'socialMedia': [null],
        'imageURL': [''],
        'state': [this.stateName],
        'district': [this.districtName],
        'city': [this.userProfileService.userProfile['city']]
      });
    }
  }

  openDialog() {
    const dialogRef = this.dialog.open(UploadTaskComponent);
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      this.defaultImage = result;
      console.log("defaultImage" + this.defaultImage);
    });
  }

  
  editProfile(){
    this.customerFlag=0;
    this.editFlag=1;
    this.setFormState();
  }

  submitForm(value: any) {
    this.createProfile(value);
    this.registerForm.reset();
  }
  createProfile(data: any) {
    data.imageURL = this.defaultImage;
    data.state= this.stateName;
    data.district=this.districtName;
    console.log("*********profiel data**"+data);
    this.firebaseService.createRow(data)
      .then(
        res => {
          console.log("response:" + res);
          this.getCustomer(this.cusId);
        }
      );
  }

  updateForm(value: any) {
    this.updateProfile(value);
  }
  updateProfile(data:any){
    data.imageURL = this.defaultImage;
    data.state= this.stateName;
    data.district=this.districtName;
    console.log("**profile date***"+data);
    this.firebaseService.updateCustomer(this.id,data)
      .then(
        res => {
          console.log("response:" + res);
          this.getCustomer(this.cusId);
        }
      );
  }

  getCustomer(data:string) {
    this.firebaseService.getCustomer(data).subscribe(
      (response)=>{
        response.forEach(element => {
          let member:any=element.payload.doc.data();
          //member.id=element.payload.doc.id;
          this.id=element.payload.doc.id;
          this.members=[];
          this.members.push(member);
          if(member.imageURL && member.imageURL!="")
          {
           this.defaultImage = member.imageURL;
         }
          console.log(element.payload.doc.data());
        });
   
        if(this.members[0]==null){
          this.customerFlag=0;
        }else{
          this.customerFlag=1;
          this.registeredCustomer=this.members[0];
          console.log("this.registeredCustomer"+this.registeredCustomer);
        }
      },
      error=>{
        console.log(error.status);
      }
    )
  }

  getStates(code, state) {
    this.loginService.getState().subscribe(response => {
      let res = response as any;
      if (res.errors) {       
      } else {
        this.states = res;
        this.states.forEach(element => {
          if(element.Code==state)
          {
            this.stateName=element.Name;
          }
        });
      }
    },
      error => {
       
      }
    );
  }
  getDistricts(code, district) {
    console.log("get district" + code);
    this.loginService.district(code).subscribe(response => {
      let res = response as any;
      console.log("district" + res);
      if (res.errors) {
       
      } else {
        console.log("district" + res);
        this.districts = res;
        this.districts.forEach(element => {
          if(element.Code==district)
          {
            this.districtName=element.Name;
          }
        });
      }
    },
      error => {       
      }
    );
  }



}
