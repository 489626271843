/**
 * HasPermissionDirective
 * 
 * @copyright Digitalpeti Technologies Ltd.
 */
import { Directive, Input, ElementRef } from '@angular/core';
import { UserProfileService } from '../../providers/service/user-profile.service';

@Directive({
    selector: '[hasMasterPermission]'
})
export class HasMasterPermissionDirective {

    @Input('hasMasterPermission') routerLink: string;

    constructor(private eleRef: ElementRef,
        private userProfileService: UserProfileService) {
        console.log(eleRef);
        let route = eleRef.nativeElement.name;
        let hasPermission: boolean = false;
        // console.log('HasMasterPermissionDirective', route);
        hasPermission = this.userProfileService.hasPermission(route);
        if (!hasPermission) {
            eleRef.nativeElement.style['pointer-events'] = 'none';
            eleRef.nativeElement.style.opacity = 0.5;
            eleRef.nativeElement.style['line-height'] = '36px';
            eleRef.nativeElement.classList.add('no-access');
        }
    }
}