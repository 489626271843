/**
 * Module
 * 
 * @copyright Digitalpeti Technologies Ltd.
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import {HelpRoutingModule } from './help-routing.module';
import { HelpComponent } from './help.component';
import { FaqComponent } from './faq/faq.component';
import { LegalComponent } from './legal/legal.component';
import { ContactComponent } from './contact/contact.component';
import { SupportComponent } from './support/support.component';
import {TncComponent} from './tnc/tnc.component';

@NgModule({
    declarations: [
        HelpComponent,
        FaqComponent,
        LegalComponent,
        ContactComponent,
        SupportComponent,
        TncComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        SharedModule,
        HelpRoutingModule,
        TranslateModule.forChild()       
    ], exports: [
        HelpComponent,
        SharedModule
    ], providers: [    ],
    entryComponents: []
})
export class HelpModule {

}
