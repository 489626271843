/**
 * Component
 * 
 * @copyright Digitalpeti Technologies Ltd.
 */
import { Component, OnInit } from '@angular/core';
import { UserProfileService } from 'src/app/providers/service/user-profile.service';
import { CommonService } from '../../../../providers/service/common-services.service';
import { SharedService } from 'src/app/providers/service/shared-services.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { FirebaseService } from 'src/app/providers/service/firebase.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  public vistedFunctions: any;
  depositForm: FormGroup;
  userId:any;
  amount: number;
  totalAmount:number=0;
  lastTransactionAmount:number;
  lastTransactionDate:string="";
  gullakData:Array<any>=[];
  config:any = 
    {  "country": "IN", "phonecode": "91", 
                        "currency": "₹", 
                        "currencyCode": "INR", 
                        "amountData": [1, 5, 11, 21, 31, 41,51, 101, 201, 501,1001],
                        "piggyAmountData": [1, 10, 50, 100, 500, 500, 1000, 2000, 5000] }
  ;
  constructor(
    public userProfileService: UserProfileService,
    public commonService: CommonService,
    private sharedService: SharedService,
    private translateService: TranslateService,
    private router :Router,
    private fb: FormBuilder,
    private firebaseService:FirebaseService
  ) {
    this.getOrgData();
    let userid=this.userProfileService.userProfile['userId'];
    if(userid!=null){
     this.userId=userid.toString();
     this.getGullakData(this.userId);
     }
  }

  ngOnInit() {    
    //this.getVisitedFunctions();
    this.depositForm = this.fb.group({
      amount: [null, Validators.required]
    });
    if (!this.userProfileService.isLoggedIn) 
    {
      this.router.navigate(['/login']);
    }
    
  }

  getGullakData(userId:String){
    this.firebaseService.loadGullakData(userId).subscribe((res)=>{
      let gullakdata:any=res.payload.data();
      this.gullakData=[];
      this.gullakData.push(gullakdata);
      if(this.gullakData.length>0 && this.gullakData[0]){
        this.lastTransactionAmount=this.gullakData[0].lastTransactionAmount;
        this.totalAmount=this.gullakData[0].totalAmount;
        this.lastTransactionDate=this.gullakData[0].lastTransactionDate;
      }
    })
  }

   /* add amount in amount field */
   addAmount(value: number) {
    if (!this.amount) {
      this.amount = 0;
    }
    this.amount = this.amount + this.config.amountData[value];
  }

  getOrgData() {
    this.commonService.getOrganisationList()
      .subscribe((res) => {
        console.log(" response data" + JSON.stringify(res));
        this.sharedService.organisation = res['organisation'];
        console.log(" response data11" + this.sharedService.organisation);
      })
  }

   /* Reset Amount */
   reset() {
    this.amount = null;
  }

  depositOnline(value: any) {
    this.totalAmount=this.totalAmount+value.amount;
    this.lastTransactionDate=(new Date()).toString();
    this.lastTransactionAmount=value.amount;
    this.userProfileService.userProfile
    let request ={
      "lastTransactionAmount":value.amount,
      "lastTransactionDate":this.lastTransactionDate,
      "userId":this.userProfileService.userProfile['userId'],
      "TotalAmount":this.totalAmount,
      "mobileNumber": this.userProfileService.userProfile['mobileNumber'],
      "userName":this.userProfileService.userProfile['userName'],
      "city":this.userProfileService.userProfile['city']
    } 
    console.log("data to save in server"+JSON.stringify(request));
    this.amount=null;
    if(this.gullakData.length==0){
      this.firebaseService.createGullakData(request).then((res)=>{
        console.log(res);
      })
    }else{
      this.firebaseService.updateGullakData(request).then((res)=>{
        console.log(res);
      })
    }
   //here get amount from server and add value and update in server
  }
  /* 
    Function to update last visted page by user
  */
  getVisitedFunctions() {
    let user = this.userProfileService.getUserProfile();
    this.commonService.getVisitedFunctions(user['staffNumber'])
      .subscribe((res) => {
        this.vistedFunctions = res;
      })
  }
}
