/**
 * ErrorService
 * 
 * @copyright Digitalpeti Technologies Ltd.
 */
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorValidationMessage } from 'src/app/core/const/ErrorValidationMessage';
import { ApplicationConfigurationService } from 'src/app/common/services/application-configuration.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor(private applicationConfigurationService :ApplicationConfigurationService) {

  }
  
  getClientErrorMessage(error: Error): string {
    return error.message ?
      error.message :
      error.toString();
  }

  getServerErrorMessage(error: HttpErrorResponse): string {
    let resMessage: string;
    resMessage = this.getValidationError(error);
    return navigator.onLine ?
      resMessage :
      'No Internet Connection';
  }

  getValidationError(error) {
    let fieldName: string;
    var splitted: string;
    switch (error.status) {
      case 400: {
        if ((error.error.messageList[0].code).includes('.')) {
          error.error.messageList.forEach(message => {
            splitted = message.code.split(".");
            if (fieldName) {
              fieldName = fieldName + ' , ' + ErrorValidationMessage[splitted[2]];
            } else {
              fieldName = ErrorValidationMessage[splitted[2]];
            }
          });
          return fieldName + ' is ' + splitted[0];
        } else if(ErrorValidationMessage[error.error.messageList[0].code]) {
          return ErrorValidationMessage[error.error.messageList[0].code];
        } else {
          return error.error.messageList[0].code;
        }
      }
      case 500: {
        // return ErrorValidationMessage[error.status];
        return ErrorValidationMessage['system_error'];
      }
      case 409: {
        if (error.error == null) {
          return ErrorValidationMessage[error.status];
        }
        if (error.error.messageList.length > 0) {
          let splitted = error.error.messageList[0].code.split(".");
          if (splitted[2] === 'flight') {
            return ErrorValidationMessage[splitted[2]] + error.error.messageList[0].referenceId;
          } else {
            return ErrorValidationMessage[splitted[2]];
          }
        } else {
          return ErrorValidationMessage[error.status];
        }
      }
      case 403: {
        return ErrorValidationMessage[error.status];
      }
      case 404: {
        if (error.error.messageList && error.error.messageList.length >0 ) {
          return error.error.messageList[0].code;
        } else {
          return ErrorValidationMessage[error.status];
        }
      }
      /*
      case 400: {
        return error.error.messageList[0].code;
      }*/
      case 412: {
        return error.error.messageList[0].code;
      }
      case 504: {
        return ErrorValidationMessage['system_error'];
      }
      case 401: {
        let fieldName: string="";
        if(error.error.userProfile) {
          if (error.error.userProfile.active && error.error.userProfile.active == "N") {
            fieldName =fieldName +"Your account is inactive. <br/>";
          } 
          if (error.error.userProfile.locked && error.error.userProfile.locked == "Y") {
            fieldName =fieldName +"Your account is locked. <br/>";
          } 
          if (error.error.userProfile.passwordExpired) {
            fieldName =fieldName +"Your password is expired. <br/>";
          }
          if (fieldName !== "") {
            fieldName =fieldName + "Please contact your administrator."
          }
        } else {
          fieldName = ErrorValidationMessage['unauthorized'];
        }
        return fieldName;
      }
      case 205: {
        return ErrorValidationMessage['concurrecy_error'];
      }
      default: {
        return ErrorValidationMessage.default;
      }
    }
  }

  /**
   * 
   * @param error In case server time out 
   */
  getTimeOutErrorMessage(error: Error): string {
    return this.applicationConfigurationService.HTTP_TIMEOUT_MESSAGE;
  }
}
