/**
 * ApplicationConfigurationService
 * 
 * @copyright Digitalpeti Technologies Ltd.
 */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { RestService } from 'src/app/core/services/restful/rest.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ApplicationConfigurationService {
    public autoScroll:boolean = false;
    public currScrollPosition:any = 0;
    public scrollPosition: BehaviorSubject<Number> = new BehaviorSubject(Number());
    getCurrScrollPosition():Observable<any> {
        return new Observable(fn => this.scrollPosition.subscribe(fn));
    }

    MENU_LIST: any;
    SPINNER_COLOR :any ;
    SPINNER_MODE :any ;
    SPINNER_VALUE :any ;
    APPLICATION_ID :any ;
    ENABLE_AUTH_HEADER :any ;
    DISABLE_WITH_CREDENTIALS :any ;
    ENABLE_GUARD :any; 
    HTTP_TIMEOUT_DURATION :any ; // 15 MINUTES
    HTTP_TIMEOUT_MEHTTPSSAGE :any ;
    ROSTER_INVALID_FILE :any ;
    HTTP_TIMEOUT_MESSAGE :any ;
    /*rss_taskAssignment_timelineStart:any ;
    rss_taskAssignment_timelineEnd:any ;
    rss_taskAssignment_previousHour:any ;
    rss_taskAssignment_nextHour:any ;
    rss_taskAssignment_recordPerPage:any ;
    rss_odcTask_searchPreviousHour:any ;
    rss_odcTask_searchNextHour:any ;
    rss_taskAssignment_position:any ;
    rss_taskAssignment_preTaskTime:any ;
    rss_taskAssignment_postTaskTime:any ;
    rss_taskAssignment_flightInterval:any ;
    rss_taskAssignment_taskInterval:any ;
    rss_taskAssignment_assignTaskType :any ;
    rss_nonFlight_operationalMealBreakId:any ;
    rss_nonFlight_lightDutyMealBreakId:any ;
    rss_odcTaskAssignment_interval:any ;
    default_incoming_message:any ;
    rss_DEFAULT_TASK_START_TIME:any ;
    rss_DEFAULT_TASK_END_TIME:any ;
    common_SEARCH_INTERVAL:any ;
    common_ADD_DAYS:any ;
    scheduleDateConfig :any ;
    returnDateConfig:any ;
    DEFAULT_SESSION_TIMEOUT :any ;
    NEVER_SESSION_TIMEOUT :any ;
    EQUIPMENT_ASSIGNMENT_TIME_MARGIN :any ;
    EQUIPMENT_ASSIGNMENT_TIME_IN_MARGIN:any;
    EQUIPMENT_ASSIGNMENT_TIME_OUT_MARGIN:any;
    ALLOWED_FILE_TYPES:any ;
    FILE_SIZE :any ;
    equipment_maintenance_status:any ; // "S"-> "Serviceable", "U"-> Unserviceable
    devex_allowedPageSizes :any ;
    devex_pageSize :any ;
    common_message_log_telex_type:any ;
    equipment_phone_category:any ;
    TASK_STATUS_PENDING_CODE :any ;
    TASK_STATUS_COMPLETE_CODE :any ;
    TASK_STATUS_MAB_CODE :any ;
    TASK_STATUS_WIP_CODE:any ;
    TASK_STATUS_ACK_CODE :any ;
    weather_const :any ;
    weatherFineCode :any ;
    weatherRainyCode :any ;
    weatherWetCode :any ;
    weatherWindyCode :any ;
    taskEndTimeCode:any ;
    taskStartTimeCode:any ;
    doorOpenTimeCode:any ;
    fwdDoorType:any ;
    aftDoorType:any ;
    bulkDoorType:any ;
    taskUnloadingStartTimeCode:any ;
    taskUnloadingEndTimeCode:any ;
    valuableReceiptUnload:any ;
    taskLoadingStartTime:any ;
    taskLoadingEndTime:any ;
    notocReceipt:any ;
    valuableReceiptLoadCode:any ;
    baggOffloadTimeCode:any ;
    remarksVoiceToTextCode:any ;
    equipmentNumberCode:any ;
    equipmentMDLCode:any ;
    equipmentFWDLDLCode:any ;
    equipmentAFTLDLCode:any ;
    equipmentTractorsCode:any ;
    equipmentConveyorsCode:any ;
    baggageUnloadULDCode:any ;
    cargoUnloadULDCode:any ;
    baggOffloadULDCode:any ;
    cargoOffloadULDCode:any ;
    remarksHandwriting:any ;
    mealEarliestStartDateTime :any ;
    mealLatestStartDateTime :any ;
    staff_teamReformation_beforeHours :any ;
    staff_teamReformation_afterHours :any ;
    nonFlightLightDutyWorkarea :any ;
    nonFlightOperationalDutyWorkarea :any ;
    APPLICATION_ID :any ;
    ENABLE_AUTH_HEADER :any ;
    DISABLE_WITH_CREDENTIALS :any ;
    ENABLE_GUARD :any ;
    IN :any ;
    OUT :any ;
    ALL :any ;
    NOT_ASSIGNED :any ;
    ASSIGNED :any ;
    CLOCKED_IN :any ;
    NOT_CLOCKED_IN :any ;
    TIME_PATTERN :any ;
    SHIFT_TIME :any ;
    SHIFT_OT_TIME :any ;
    ID_PATTERN :any ;
    ALPHA_NUM :any ;
    ALPHA :any ;
    ALPHA_ONLY :any ;
    NUMBER :any ;
    NUMBERWITHSLASH :any ;
    SEARCH :any ;
    ALL_ALPHA :any ;
    MOBILE_NUMBER :any ;
    ID :any ;
    SIZE_PATTERN :any ;
    NAME_PATTERN :any ;
    DEFAULT_START_TIME :any ;
    DEFAULT_END_TIME :any ;
    RHS_DEFAULT_START_TIME :any ;
    ENQUIRE_TEAM_DEFAULT_START_TIME :any ;
    ENQUIRE_TEAM_DEFAULT_END_TIME :any ;
    RHS_DEFAULT_END_TIME :any ;
    ROSTER_DEFAULT_START_TIME :any ;
    ROSTER_DEFAULT_END_TIME :any ;
    SEARCH_INTERVAL :any ; // In Months
    ADD_DAYS :any ;
    INTERVAL_MONTH :any ;
    INTERVAL_DAY :any ;
    SPINNER_COLOR :any ;
    SPINNER_MODE :any ;
    SPINNER_VALUE :any ;
    IN_TEAM :any ;
    INDIVIDUAL :any ;
    flight_DEFAULT_START_TIME :any ;
    flight_types :any ;  // "0"=>"A" for Arrival, "1"=>"D" for departure, "2"=>"TR" for Turnaround
    flight_paired_flags :any ; // "0" for unpaired, "1" for paired
    flight_turnaround_flags :any ; // "0" for unpaired, "1" for turnarounnd
    flight_status_keys :any ; // "0"=>"A" for Active, "1"=>"X" for cancelled
    flight_default_port :any ;
    flight_leg_actions :any ; // i-> insert, u->update, d->delete, x->no action
    flight_nhs :any ;
    flight_schedule_flights_status :any ;
    flight_turnaround :any ;
    disabled_text_class :any ;
    enabled_text_class :any ;
    otherMessageType :any ;
    common_invalid_date :any ;
    DOC_FILE_TYPE :any ;
    DATE_TIME_DISPLAY_CONST :any ; // "0"=>"A" for Actual, "1"=>"E" for Estimated,  "2"=>"S" for Scheduled
    CREATED_BC :any ;
    CREATED_WC :any ;
    CREATED_BC_SIGN :any ;
    CREATED_WC_SIGN :any ;
    HASH_SIGN :any ;
    SERVICE_TYPES :any ;
    EQUIPMENT_STATUS :any ; // "A" for Assigned, "R" for Returned
    MAINTENANCE_STATUS :any ; // "S" for Serviceable, "U" for Unserviceable
    DEFAULT_ALL :any ;
    ARR :any ;
    DEPT :any ;
    A :any ;
    D :any ;
    COMPLETE_STATUS :any ;
    O :any ;
    S :any ;
    StaffNumber_MAX_LENGTH :any ;
    RECORD_FLAG_U :any ;
    RECORD_FLAG_I :any ;
    RECORD_FLAG_D :any ;
    TRANSFER_TYPE_BH :any ;
    TRANSFER_TYPE_BS :any ;
    TRANSFER_TYPE_BT :any ;
    SITA_MSG_FLAG :any ;
    SITA_MSG_DEFAULT_FLAG :any ;
    STATUS_COMPLETE :any ;
    STATUS_OPEN :any ;
    STATUS_OPEN_LATE :any ;
    ETD :any ;
    COURIER :any ;
    CARGO :any ;
    MAIL :any ;
    BAGGAGE :any ;
    REVOKE :any ;
    SWAP_FLAG_O :any ;
    SWAP_FLAG_S :any ;
    SWAP_FLAG_Y :any ;
    SWAP_FLAG_N :any ;
    SWAP_FLAG_R :any ;
    SWAP_FLAG_C :any ;
    SWAP_FLAG_D :any ;
    OUTSTANDING :any ;
    COMPLETE :any ;
    FLIGHT_INTERVAL :any ;
    TASK_INTERVAL :any ;
    ODC_TASK_INTERVAL :any ;
    DEFAULT_TASK_START_TIME :any ;
    DEFAULT_TASK_END_TIME :any ;
    NON_FLIGHT_TASK_OPERATIONAL_MEAL_BREAK_CODE :any ;
    NON_FLIGHT_TASK_LIGHT_DUTY_MEAL_BREAK_CODE :any ;
    NON_FLIGHT_TASK_OPERATIONAL_TEA_BREAK_CODE :any ;
    NON_FLIGHT_TASK_LIGHT_DUTY_TEA_BREAK_CODE :any ;
    NON_FLIGHT_TASK_OPERATIONAL_CATEGORY :any ;
    NON_FLIGHT_TASK_LIGHTDUTY_CATEGORY :any ;
    TASK_ASSIGNMENT_TEAM_CODE :any ;
    TASK_ASSIGNMENT_STAFF_CODE :any ;
    TASK_ASSIGNMENT_ACK_CODE :any ;
    ARRIVAL :any ;
    DEPARTURE :any ;
    DATE_TIME_FORMAT :any ;
    BASE_URL :any ;
    MONTHS :any ;
    STAFF_TYPES :any ;
    DEPARTMENTS :any ;
    YEARS :any ;
    sections :any ;
    ABSENCE_CODES :any ;
    STAFFREPORTSERVICEURL :any ;
    RSSREPORTSERVICEURL :any ;
    HKG_PORT :any ;
    STANDARD_DATE_TIME_FORMAT :any ;
    STANDARD_DATE_TIME_FORMAT_DDMMYYYHHMMSS :any ;
    TASK_STATUS_OPEN :any ;
    TASK_CATEGORY_OTHER_CODE :any ;
    
    STATUS_CODE_0 :any ;
    STATUS_CODE_1 :any ;
    STATUS_CODE_2 :any ;
    ACTIVATION_FLAG :any ;
    NONE :any ;
    STAGING :any ;
    TASK_STATUS :any ;
    OTIS_STATUS :any ;
    BAGGAGE_TRANSFER_LOCATION :any ;
    PROCESS_EDI_MQ_CODE :any ;
    AUDIT_LOG_CREATE :any ;
    AUDIT_LOG_UPDATE :any ;
    NEVER :any ;
    DEFAULT :any ;
    YES :any ;
    NO :any ;
    PARTIAL :any ;
    READ_ONLY :any ;
    FULL :any ;
    PERMANENT :any ;
    UPDATE :any ;
    INSERT :any ;
    INVALID_DATE :any ;
    HTTP_TIMEOUT_DURATION :any ; // 15 MINUTES
    HTTP_TIMEOUT_MESSAGE :any ;
    PASSWORD_REGEX :any ;
    ALERT_ACK_SCREEN :any ;
    ALERT_KEY :any ;
    TEAM_SHIFT_END_ALERT_MINUTES :any ;
    STAFF_SHIFT_END_ALERT_MINUTES :any ;
    TEAM_SHIFT_MARGIN_MINUTES :any ;
    STAFF_SHIFT_MARGIN_MINUTES:any ;
    color_schemes : any; //[Production,UAT,Staging,Training]
    color_scheme: any;
    staffIdMaxLength : any;
    TEAM_ID_MAX_LENGTH : any;
    STAFf_NAME_PATTERN : any;
    CARD_ID_MAX_LENGTH : any;
    PERMIT_NUMBER_MAX_LENGTH : any;
    TEAM_DESC_MAX_LENGTH : any;
    TEAM_SIZE_MAX_LENGTH : any;
    HH_MM_SS : any;
    TIME_FORMAT_MAX_LENGTH : any;
    MINUTES_CONFIG : any;
    BSS_MINUTES_CONFIG:any;
    OT_BEFORE : any;
    OT_AFTER : any;
    MEAL_EARLIEST_CONFIG : any;
    MEAL_LATEST_CONFIG : any;
    OT_CONFIG_1 : any;
    OT_CONFIG_2 : any;
    OT_CONFIG_3 : any;
    OT_CONFIG_4 : any;
    SHIFT_DIFF_CONFIG : any;
    OT_PATTERN : any;
    FLIGHT_NO_MAX_LENGTH  : any;
    FLIGHT_NO_LENGTH : any;
    FLIGHT_ID_MAX_LENGTH  : any;
    OTIS_TO_DATE_CONFIG : any;
    WEIGHT_PATTERN : any;
    AWB_NO_MAX_LENGTH : any;
    SPECIAL_HANDLING_CODE_MAX_LENGTH : any;
    ULD_MAX_LENGTH  : any;
    PIECES_MAX_LENGTH : any;
    PAX_MAX_LENGTH : any;
    BAGGEGE_TYPE_MAX_LENGTH : any;
    LOAD_CATE_MAX_LENGTH : any;
    ROLE_CODE_MAX_LENGTH : any;
    ESTIMATED : any;
    SCHEDULED : any;
    ATCUAL : any;
    ABAY : any;
    DBAY : any;
    ARR_BAY : any;
     DEP_BAY : any;
    PENDING : any;
    ACD : any;
    WIP : any;
    COM : any;
    AT_STAGGING  : any;
    STG : any;
    BAY : any;
    ALA5 : any;
    ALA6 : any;
    ACTUAL : any;
    RELEASE : any;
    DELETE : any;
    ABAY_A : any;
    DBAY_D : any;
    STG_S : any;
    BAY_MAX_LENGTH : any;
    AC_REG_MAX_LENGTH : any;
    REMARKS_MAX_LENGTH : any;
    assignmentStatusCode : string[] = ['P','A','W','C']; // P:Pending, A:Acknowledge, W:WIP, C:Completed
    heilightCellBkground: string = '#f24b4b';
    delayCodeFormLength = 5;    
    NUMBERWITHCOMMA = '^[0-9,]*$';
    ALPHANUMWITHSPACE = '^[a-zA-Z0-9 ]*$';
    passengerFreighterValues : string[] = ['Passenger','Freighter','Combi'];
    RHS_DATE : any;
    RECORD_DELETE_MSG : any;
    RECORD_SAVE_MSG : any;
    INVLAID_DATE_RANGE : any;   
    DEFAULT_LIMIT : any;
    DEFAULT_OFFSET : any; 
    MESSAGE_LIMIT: any;
    FLIGHT_LIMIT: any;
    STAFF_LIMIT: any;
    AUDIT_LIMIT: any;
    BLANK_MODEL_DATA : any;
    DAOT_FLAG : any;
    SSLTIME: any;
    EMAIL_PATTERN : string = '[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}';
    BREAK_TIME_OFFSET : string;
    
    flt_monitoring_previousHour:any ;
    flt_monitoring_nextHour:any ;
    DEFAULT_PAGE_SIZE:any;
    DEFAULT_PAGE_INDEX:any;
    DATE_ERROR : any;
    ONE_TWENTY : any;
    THREE_SIXTY : any;
    TWO_FOURTY:any;
    WEEK_DAYS:any;
    DURATION_90:any;
    bssArrivalTaskAssignmentPreviousHour:any ;
    bssArrivalTaskAssignmentNextHour:any ;
    bssDepartureTaskAssignmentPreviousHour:any ;
    bssDepartureTaskAssignmentNextHour:any ;
    LATERAL_START_TIME:any;
    LATERAL_END_TIME: any;
    LATERAL_TIME_DURATION: any;
    
    constructor(public restService: RestService,
        public http: HttpClient,
        public loggerService: NGXLogger) {

    }

    setStaffTeamReformationBeforeHours(value) {
        this.staff_teamReformation_beforeHours = value;
    }

    getStaffTeamReformationBeforeHours() {
        return this.staff_teamReformation_beforeHours;
    }

    setStaffTeamReformationAfterHours(value) {
        this.staff_teamReformation_afterHours = value;
    }

    getStaffTeamReformationAfterHours() {
        return this.staff_teamReformation_afterHours;
    }

    setRssTaskAssignmentTimelineStart(value) {
        this.rss_taskAssignment_timelineStart = value;
    }

    setRssTaskAssignmentTimelineEnd(value) {
        this.rss_taskAssignment_timelineEnd = value;
    }

    setRssTaskAssignmentPreviousHour(value) {
        this.rss_taskAssignment_previousHour = value;
    }

    getRssTaskAssignmentPreviousHour() {
        return this.rss_taskAssignment_previousHour;
    }

    setRssTaskAssignmentNextHour(value) {
        this.rss_taskAssignment_nextHour = value;
    }

    getRssTaskAssignmentNextHour() {
        return this.rss_taskAssignment_nextHour;
    }

    setRssTaskAssignmentPreTaskTime(value) {
        this.rss_taskAssignment_preTaskTime = value;
    }

    getRssTaskAssignmentPreTaskTime() {
        return this.rss_taskAssignment_preTaskTime;
    }

    setRssTaskAssignmentPostTaskTime(value) {
        this.rss_taskAssignment_postTaskTime = value;
    }

    getRssTaskAssignmentPostTaskTime() {
        return this.rss_taskAssignment_postTaskTime;
    }

    setRssTaskAssignmentPosition(value) {
        this.rss_taskAssignment_position.position1 = value.position1;
        this.rss_taskAssignment_position.position2 = value.position2;
        this.rss_taskAssignment_position.position3 = value.position3;
        this.rss_taskAssignment_position.position4 = value.position3;
    }

    getRssTaskAssignmentPosition() {
        return this.rss_taskAssignment_position;
    };

    getRssTaskAssignmentAssignTaskType() {
        return this.rss_taskAssignment_assignTaskType;
    }

    getScheduleDateConfig() {
        return this.scheduleDateConfig;
    }

    getReturnDateConfig() {
        return this.returnDateConfig;
    }

    getRssOdcTaskSearchPreviousHour() {
        return this.rss_odcTask_searchPreviousHour;
    }

    getRssOdcTaskSearchNextHour() {
        return this.rss_odcTask_searchNextHour;
    }

    getDevexAllowedPageSizes() {
        return this.devex_allowedPageSizes;
    }

    getDevexPageSize() {
        return this.devex_pageSize;
    }

    getDefaultSessionTimeout() {
        return this.DEFAULT_SESSION_TIMEOUT;
    }

    getNeverSessionTimeout() {
        return this.NEVER_SESSION_TIMEOUT;
    }

    getCommonMessageLogTelexType() {
        return this.common_message_log_telex_type;
    }

    getDefaultIncomingMessage() {
        return this.default_incoming_message
    }

    getMealEarliestStartTime() {
        return this.mealEarliestStartDateTime;
    }

    getMealLatestStartTime() {
        return this.mealLatestStartDateTime;
    }

    getRssTaskAssignmentRecordPerPage() {
        return this.rss_taskAssignment_recordPerPage;
    }

    getNonFlightOperationalDutyWorkarea() {
        return this.nonFlightOperationalDutyWorkarea;
    }
    getNonFlightLightDutyWorkarea() {
        return this.nonFlightLightDutyWorkarea;
    }

    getWeekDays() {
        return this.WEEK_DAYS;
    }*/
}
