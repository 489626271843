
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DATE_LOCALE } from '@angular/material';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NoopAnimationsModule, BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DialogBoxComponent } from './common/components/dialog-box/dialog-box.component';
import { FooterComponent } from './common/components/footer/footer.component';
import { HeaderComponent } from './common/components/header/header.component';
import { LoaderComponent } from './common/components/loader/loader.component';
import { ApplicationConfigurationService } from './common/services/application-configuration.service';
import { CommonService } from './providers/service/common-services.service';
import { SharedService } from './providers/service/shared-services.service';
import { ConfigurationService } from './configuration.service';
import { AuthGuardService } from './core/security/authguard';
import { NewHTTPService, NewHTTPServiceFactory } from './core/services/http/http.service';
import { HTTPInterceptorService } from './core/services/interceptor/http.interceptor';
import { NewRestServiceFactory, NewRestService } from './core/services/restful/rest.new.service';
import { RestService } from './core/services/restful/rest.service';
import { GlobalErrorHandler } from './providers/service/exception-handler/global-error-handler';
import { LoaderService } from './providers/service/loader/loader.service';
import { LoginModule } from './domain/digitalpeti/login/login.module';
import { SharedModule } from './shared/shared.module';
import {MemberModule} from './domain/members/member.module';
import {OrganisationDetailsModule} from './domain/digitalpeti/organisation-details/organisation-details.module';
import {DonationModule} from './domain/digitalpeti/donation/donation.module';
import {AdminModule} from './domain/admin/admin.module';
import {HelpModule} from './domain/help/help.module';
import { AgGridModule } from 'ag-grid-angular';
export function configurationServiceFactory(provider: ConfigurationService) {
  return () => provider.loadConfigurationData();
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    DialogBoxComponent,
    LoaderComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
    NoopAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    LoginModule,
    HelpModule,
    SharedModule,
    MemberModule,
    OrganisationDetailsModule,
    AdminModule,
    DonationModule,
    AppRoutingModule,
    AgGridModule.withComponents([]),
    LoggerModule.forRoot({
      level: NgxLoggerLevel.DEBUG,
      serverLogLevel: NgxLoggerLevel.ERROR
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  providers: [ConfigurationService,
    {
      provide: APP_INITIALIZER,
      useFactory: configurationServiceFactory,
      deps: [ConfigurationService],
      multi: true
    },
    HttpClient, {
      provide: NewHTTPService, useFactory: NewHTTPServiceFactory, deps: [HttpClient]
    }, 
     {
      provide: RestService, useFactory: NewRestServiceFactory, deps: [NewHTTPService]
    }, {
      provide: HTTP_INTERCEPTORS, useClass: HTTPInterceptorService, multi: true
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }, // Makes all the default date formats as DD/MM/YYYY
    LoaderService,
    SharedService,
    CommonService,
    ApplicationConfigurationService,
    AuthGuardService],
  bootstrap: [AppComponent],
  entryComponents: [DialogBoxComponent]
})
export class AppModule {HelpModule }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
