
export const ErrorValidationMessage = {
    409: "Record already exists",
    403: "Record can not be deleted",
    404: "Requested url not found.",
    500: "Error occured while processing the record",
    system_error: "The service has encountered an error processing your request. Please try again. If still facing issue, Please report to support team.",
    default: "The Server is temporarily unable to service your request due to downtime.Please try again later",
    unauthorized: "Username or Password is Invalid",
    session_expire:"Session Expired,",
    concurrecy_error: "The record you are working on has been modified by another user. Please review their changes before trying again.",
   
};
