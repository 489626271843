import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { UsersOrgService } from 'src/app/providers/service/users.org.service';

@Component({
  selector: 'app-deposit',
  templateUrl: './deposit.component.html',
  styleUrls: ['./deposit.component.scss']
})
export class DepositComponent implements OnInit {

  depositForm: FormGroup;
  amount: number;
  scheme: any;
 
  isdisabled:Boolean=false;
  blankValue:string="";
  config:any = 
    {  "country": "IN", "phonecode": "91", 
                        "currency": "₹", 
                        "currencyCode": "INR", 
                        "amountData": [1, 10, 20, 50, 100, 500, 1000, 2000, 5000],
                        "piggyAmountData": [1, 10, 50, 100, 500, 500, 1000, 2000, 5000] }
  ;
  constructor(
    //public alertCtrl: AlertController,           
//    public storage: Storage,
    //public toastCtrl: ToastController,
    //public logger: Logger,
    //public navParams: NavParams,
    //public navCtrl: NavController,
    //public userData: UserData,
    //public events: Events,
    public translate: TranslateService,
    private fb: FormBuilder,
    //public configData: Config,
    public usersOrgService: UsersOrgService) {
       
  }

 ngOnInit(): void {
  //this.config = this.configData.getCountryData(this.userData.profile.country);
    this.depositForm = this.fb.group({
      amount: [null, Validators.required],
      "purpose": [null,Validators.required]
    })
}
  /* Invoked this event when page load */
  ionViewDidLoad() {
    //this.logger.log(this.logger.DEBUG, "DepositPage:ionViewDidLoad");
  }

  /* Invoked this event when page revisit or enter- */
  ionViewDidEnter() {
    //this.logger.log(this.logger.DEBUG, "DepositPage:ionViewDidLoad");   
  }

  /* add amount in amount field */
  addAmount(value: number) {
    if (!this.amount) {
      this.amount = 0;
    }
    this.amount = this.amount + this.config.amountData[value];
  }

  /* add scheme in purpose field */
  addScheme(event) {
    if(event)
    {
      this.amount = event.amount;
      this.scheme = event.name;
      this.isdisabled=true;
      //this.logger.log(this.logger.DEBUG, "DepositPage:addScheme:" + JSON.stringify(event));
    }
  }

 /* add scheme in purpose field */
  addFundAccountType(event) {
    if(event)
    {
    this.scheme = event.name;
    //this.logger.log(this.logger.DEBUG, "DepositPage:addScheme:" + JSON.stringify(event));
    }
  }

  /* Reset Amount */
  reset() {
    this.amount = null;
    this.scheme = null;
    this.isdisabled=false;
  }

  /* Submit button for deposit money in Account */
  deposit(value: any) {
      //   this.logger.log(this.logger.DEBUG, "DepositPage:deposit");
          this.depositOnline(value);
    }

showPrompt(value: any, pay:boolean) {
  /*  let prompt = this.alertCtrl.create({
      title: 'Login',
      message: "Please verify your transaction pin",
      inputs: [
        {
          name: 'Pin',
          placeholder: 'Enter your pin number'
        },
      ],
      buttons: [
        {
          text: 'Cancel',
          handler: data => {
            
          }
        },
        {
          text: 'Login',
          handler: data => {
            console.log("verify pin"+JSON.stringify(data)+this.userData.profile.pin);
            if(data.Pin==this.userData.profile.pin)
            {              
              if(pay==true)
              {
                this.depositAndPay(value);
              }else
              {
                this.deposit(value);
              }
            }else
            {
              super.showMessage("You are not authorised as your pin is invalid");
            }
          }
        }
      ]
    });
    prompt.present();
  */
  }

  
  /* This method call APIS to deposit money in database */
  depositOnline(value: any) {
  /*  this.logger.log(this.logger.DEBUG, "DepositPage:DepositMoney");
    this.usersOrgService.deposit(this.userData.selectedOrganisation.userName, this.userData.selectedOrganisation.organisation,
      +value.amount, this.config.currencyCode, value.purpose, this.scheme,this.userData.profile.username,this.userData.token,this.userData.hostName)
      .then(response => {
        this.logger.log(this.logger.DEBUG, "DepositPage:DepositMoney" + response);
        if (response.errors) {
          this.logger.logData(this.logger.ERROR, "DepositPage:DepositMoney", response.errors);
        }
        if (response.organisation) {
          let userOrganisation: UserOrganisation = this.userData.selectedOrganisation;
          userOrganisation.summary = response.organisation.summary;         
          this.storeUserOrganisationSummary(this.events, userOrganisation, 'peti:Deposit');
          this.showMessageWithAmount(this.config.currency + ' ' + value.amount, 'depositPage.onlineSuccessDepositOnly');
          this.depositForm.reset();
        };
      }, error => {
        this.logger.logData(this.logger.ERROR, "DepositPage:DepositMoney", error);
        super.showMessage("systemError");
      }
      ); */
  }


  /* Submit button for deposit money from PiggyBank in Account */
  depositAndPay(value: any) {
    this.deposit(value);
    //this.userData.lastTransaction = { amount: value.amount, purpose: value.purpose };
    //this.events.publish('peti:DepositAndPay');
  }


}
