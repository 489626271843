/**
 * Staff Domain Module
 * 
 * @copyright Digitalpeti Technologies Ltd.
 */
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from 'src/app/core/security/authguard';
import { HelpComponent } from './help.component';
import { FaqComponent } from './faq/faq.component';
import { LegalComponent } from './legal/legal.component';
import { ContactComponent } from './contact/contact.component';
import { SupportComponent } from './support/support.component';
import { TncComponent } from './tnc/tnc.component';

const routes: Routes = [
    {
        path: 'members',
        children: [
            {
                path: 'help',
                component: HelpComponent,
            },
            {
                path: 'faq',
                component: FaqComponent,
            },
            {
                path: 'legal',
                component: LegalComponent,
            },
            {
                path: 'contact',
                component: ContactComponent,
            },

            {
                path: 'support',
                component: SupportComponent,
            },
            {
                path: 'tnc',
                component: TncComponent,
            }]
    }];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class HelpRoutingModule { }
