import { Component, ChangeDetectorRef, OnDestroy, EventEmitter, Output } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '../../../providers/service/shared-services.service';
import { NGXLogger } from 'ngx-logger';
import { Router } from '@angular/router';
import { UserProfileService } from 'src/app/providers/service/user-profile.service';
import { color_schemes, color_scheme } from '../../../../environments/environment';
import { CommonService } from '../../../providers/service/common-services.service';
import { LoginService } from 'src/app/domain/digitalpeti/login/login.service';

import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { tap, map, filter } from 'rxjs/operators';
import { ConfigurationService } from 'src/app/configuration.service';
import { ApplicationConfigurationService } from '../../services/application-configuration.service';
//import { MasterDataService } from '../../../domain/common/masters/services/master-data.service';

export class Menu {
  constructor(
    public name: string,
    public hasPermission: boolean,
    public child: any,
    public routerLink: string) { }
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnDestroy {

  mobileQuery: MediaQueryList;

  private mobileQueryListener: () => void;

  translateService: TranslateService;
  transLanguage: string = 'English';
  transCode: string = 'en';
  timezoneCity: string = 'Asia/Hong_Kong';
  currentTime: string = '';
  @Output() logoutEvent = new EventEmitter<string>();
  @Output() userAccess = new EventEmitter<any>();
  toolbarClass: string = '';

  stateCtrl: FormControl;
  filteredStates: Observable<any[]>;
  private searchedItems: Array<any> = [];
  private key: string;
  private prop: string;
  private childrenPropName: string;
  public headerBorder: string;
  public menuLists: Menu[];
  myObj = {
    name: "elliot1",
    age: 25
  };

  constructor(changeDetectorRef: ChangeDetectorRef, media: MediaMatcher,
    public translate: TranslateService,
    public sharedService: SharedService,
    private router: Router,
    private loginService: LoginService,
    public configurationService: ConfigurationService,
    public userProfileService: UserProfileService,
    public commonService: CommonService,
    private logger: NGXLogger,
    //public masterDataService: MasterDataService,
    private applicationConfigurationService: ApplicationConfigurationService,
  ) {
    this.headerBorder = '5px solid #e24c02';
    this.mobileQuery = media.matchMedia('(max-width: 3000px)');
    this.mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this.mobileQueryListener);
    translate.addLangs(['en', 'ch']);
    let language = sharedService.getSelectedLanguage();
    if (language === '') {
      translate.setDefaultLang(this.transCode);
      const browserLang = translate.getBrowserLang();
      translate.use(browserLang.match(/en|ch/) ? browserLang : this.transCode);
      sharedService.setSelectedLanguage(browserLang.match(/en|ch/) ? browserLang : this.transCode);
    } else {
      translate.setDefaultLang(language);
      translate.use(language);
      sharedService.setSelectedLanguage(language);
    }

    // if (language == 'en') {
    //   this.transLanguage = 'English';
    //   this.transCode = 'en';
    // } else 
    if (language == 'ch') {
      this.transLanguage = '中文';
      this.transCode = 'ch';
    } else {
      this.transLanguage = 'English';
      this.transCode = 'en';
    }

    this.translateService = translate;
    this.setToolbarClass();

    this.initInterval();
    this.initListener();
    this.stateCtrl = new FormControl();
    this.filteredStates = this.stateCtrl.valueChanges.pipe(startWith(''), map(state => state ? this.filterMenus(state) : null));

    this.configurationService.configurationData.subscribe(respone => {
      let configResponse = respone as any;
      if (configResponse && configResponse.environment && configResponse.application && configResponse.application.environment_color) {
        if (configResponse.application.environment_color[configResponse.environment]) {
          this.headerBorder = configResponse.application.environment_color[configResponse.environment];
        }
      }
    });
    this.getSystemParameter();
    this.menuLists = this.applicationConfigurationService.MENU_LIST;
  }

  @Output() onHide = new EventEmitter<boolean>();
  setHide() {
    this.onHide.emit(true);
  }

  //Method Used for Menu Expandable and Consolable
  isSelected = false;

  checkAll() {
    this.isSelected = true;
    //console.log('selected',this.isSelected);
  }

  uncheckAll() {
    this.isSelected = false;
    //console.log('selected',this.isSelected);

  }

  ngOnInit() {
    this.sharedService.setSelectedTimezone(this.timezoneCity);
    this.currentTime = this.sharedService.now();
    this.commonService.getMenus().subscribe((res) => {
      this.applicationConfigurationService['MENU_LIST'] = res['MENU_LIST'];
      this.menuLists = this.applicationConfigurationService.MENU_LIST;
    });
    
  }

  setLanguage(language: string) {
    console.log("Language ..." + language);
    this.translateService.use(language);
    if (language == 'ch') {
      this.transLanguage = '中文';
      this.transCode = 'ch';
    } else {
      this.transLanguage = 'English';
      this.transCode = 'en';
    }
    this.logger.debug('Language - ' + this.transLanguage);
    this.logger.debug('Language Code - ' + this.transCode);

    this.sharedService.setSelectedLanguage(this.transCode);
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this.mobileQueryListener);
  }

  logout() {
    this.logoutEvent.emit("Logout button clicked")
    localStorage.removeItem("digitalpetiProfile");
    this.router.navigate(['/login']);
  }

  // Set toolbar class specific to envoronment
  setToolbarClass() {
    console.log(this.applicationConfigurationService);
    this.toolbarClass = 'env-production';
    /*let classIndex = this.applicationConfigurationService.color_schemes.indexOf(this.applicationConfigurationService.color_scheme);
    if (classIndex == 0) {
      this.toolbarClass = 'env-production';
    } else if (classIndex == 1) {
      this.toolbarClass = 'env-uat';
    } else if (classIndex == 2) {
      this.toolbarClass = 'env-staging';
    } else if (classIndex == 3) {
      this.toolbarClass = 'env-training';
    } else {
      this.toolbarClass = 'env-production';
    }*/
  }
  // Below Functions is for Accordion 
  panelOpenState = false;

  initListener() {
    document.body.addEventListener('click', () => this.reset());
  }

  reset() {
    // this.lastAction = 0;
  }

  initInterval() {
    setInterval(() => {
      this.checkLogout();
    }, 60000);
  }

  checkLogout() {
    let lastAccessTime = this.sharedService.getLastAccessTime();
    let currentTime = this.sharedService.time();
    let defaultSessionTimeout = this.sharedService.getDefaultSessionTimeout();
    let sessionTimeout = this.userProfileService.sessionTimeout;

    let minuteDiff = defaultSessionTimeout - currentTime;
    minuteDiff = Math.round(minuteDiff / 60000);

    let lastAccessMinute = currentTime - lastAccessTime;
    lastAccessMinute = Math.round(lastAccessMinute / 60000);

    if (sessionTimeout != 0 && minuteDiff <= 5) {
      //this.loginService.getRefreshToken();
    } else if (sessionTimeout != 0 && lastAccessMinute >= sessionTimeout) {
      localStorage.setItem("sessionExpiredMesg", "You have been logged out because your session has expired.");
      this.logout();
    }

    /*
    if (sessionTimeout != 0 && lastAccessMinute >= sessionTimeout) {
      localStorage.setItem("sessionExpiredMesg", "You have been logged out because your session has expired.");
      this.logout();
    } else if (sessionTimeout != 0 && minuteDiff <= 5) {
      this.loginService.getRefreshToken();
    }
    */
  }

 
  /**
   * Change Current Timezone
   */
  changeTimezone(timeZone) {
    this.sharedService.setSelectedTimezone(timeZone)
  }


  filterMenus(name: string) {
    this.searchedItems = [];
    this.key = name.toLowerCase();
    this.prop = 'name';
    this.childrenPropName = 'child';
    let searchResult = this.searchRecursive(this.menuLists);
    return searchResult;
  }

  onEnter(evt: any, state) {
    if (state.routerLink != '') {
      if (this.userProfileService.hasPermission(state.functionCode)) {
        console.log(state);
        if (state.queryParams) {
          let obj = JSON.parse(state.queryParams);
          this.router.navigate([state.routerLink], { queryParams: obj });
        } else {
          this.router.navigate([state.routerLink]);
        }
      } else {
        this.commonService.showDialogBox('E', this.translateService.translations[this.transCode].errors.PERMISSION_TO_VIEW_PAGE_ERROR)
        return false;
      }
    }
  }
  searchRecursive(value) {
    for (var i = 0; i < value.length; i++) {
      let lowerCaseName = value[i][this.prop].toLowerCase();
      if (lowerCaseName.includes(this.key)) {
        if (value[i]['routerLink'] != '') {
          this.searchedItems.push(value[i]);
        }
      }
      if (value[i][this.childrenPropName]) {
        if (value[i][this.childrenPropName].length > 0) {
          this.searchRecursive(value[i][this.childrenPropName]);
        }
      }
    }
    return this.searchedItems;
  }

  getSystemParameter() {
   /* this.masterDataService.getAllData("systemparamter").subscribe(response => {
      let data = response as any;
      this.headerBorder = this.commonService.getSystemParamValue('APPHEADER', data);
    });*/
  }

  /**
  * 
  * @param obj This function parse the json object
  */
  parseJsonObject(obj) {
    if (obj) {
      let jsonObj = JSON.parse(obj);
      return jsonObj;
    }

  }

}