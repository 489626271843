/**
 * Module
 * 
 * @copyright Digitalpeti Technologies Ltd.
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule } from '@ngx-translate/core';
import {MemberRoutingModule } from './member-routing.module';
import { TradeProfileComponent } from './trade-profile/trade-profile.component';
import { AgGridModule } from 'ag-grid-angular';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { FirebaseService } from '../../providers/service/firebase.service';
import { environment } from '../../../environments/environment';
import { MemberProfileComponent } from './members-profile/member-profile.component';
import { MemberListComponent } from './members-list/member-list.component';
import { MMProfileComponent } from './mm-profile/mm-profile.component';
import { LoggingService } from '../../providers/service/logger/logging.service';
import { AngularFireDatabase} from '@angular/fire/database';
import {UploadTaskComponent} from "../../common/components/upload-task/upload-task.component";
import {ProgressComponent} from "../../common/components/progress/progress.component";
import{MemberDialogProfileComponent} from "./members-list/dialog-profile/member-profile.component";

@NgModule({
    declarations: [
        TradeProfileComponent,
        MemberListComponent,
        MemberProfileComponent,
        MMProfileComponent,
        UploadTaskComponent,
        ProgressComponent,
        MemberDialogProfileComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        SharedModule,
        MemberRoutingModule,
        AgGridModule.withComponents([]),
        TranslateModule.forChild(),
        AngularFireModule.initializeApp(environment.firebase),
        AngularFirestoreModule,
    ], exports: [
        SharedModule
    ], providers: [FirebaseService, LoggingService,AngularFireDatabase
    ],
    entryComponents: [UploadTaskComponent,MemberDialogProfileComponent]

})
export class MemberModule {

}
