import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { UsersOrgService } from 'src/app/providers/service/users.org.service';

@Component({
  selector: 'app-offline-payment',
  templateUrl: './offline-payment.component.html',
  styleUrls: ['./offline-payment.component.scss']
})
export class OfflinePaymentComponent implements OnInit {

paymentForm: FormGroup;
  amount: number;
  todayDate: String;// = new Date().toISOString();
  purpose: string;
  config: any;
  constructor(
    public fb: FormBuilder,
    public usersOrgService: UsersOrgService,
    public translateService: TranslateService) {
  }

  ngOnInit(): void {
  /*  this.config = this.configData.getCountryData(this.userData.profile.country);
    //Paymet form for cash payment method
    console.log("********oninit offlinePaymentPage");
    if (this.userData.lastTransaction) {
      this.amount = this.userData.lastTransaction.amount;
      this.purpose = this.userData.lastTransaction.purpose;
    }
    */
    this.paymentForm = this.fb.group({
      amount: [null, Validators.compose([Validators.required, Validators.pattern(/^\d*(?:[.,]\d{1,2})?$/)])],
      receiptNumber: [null, Validators.required],
      receiptDate: [null, Validators.required],
      purpose: [null],
      mode: [null, Validators.required]
    });
  }

  /* Reset Amount */
  reset() {
    this.amount = null;
    this.purpose = null;
    this.todayDate = null;//new Date().toISOString();
  }

  /* Pay Cash Money in account */
  payCash(value: any) {
    if (this.validateAmount()) {
       // this.showPrompt(value);
    }
  }

  /* Validate Amount, it should not more than balance amounts*/
  validateAmount(): boolean {
    if (this.amount < 1) {
     // this.showMessage('paymentPage.amoutIslessthanzero');
      return false;
    }
  /*  if (this.amount > 0 && this.amount > this.userData.selectedOrganisation.summary.balance) {
     // this.showMessage('paymentPage.amoutIsGreaterThanBalance');
      return false;
    }*/
    return true;
  }


   /* This method update payment details in live database */
  payCashMoneyOnline(value: any) {
 /*   this.logger.log(this.logger.DEBUG, "PaymentPage:payCashMoneyOnline:value" + value);
    this.usersOrgService.payment(this.userData.selectedOrganisation.userName, this.userData.selectedOrganisation.organisation,
      +value.amount, this.config.currencyCode, value.receiptDate, value.mode, value.purpose, value.receiptNumber, this.userData.profile.username, this.userData.token, this.userData.hostName)
      .then(response => {
        this.logger.log(this.logger.DEBUG, "PaymentPage:payCashMoneyOnline" + response);
        if (response.errors) {
          this.logger.logData(this.logger.ERROR, "PaymentPage:payment", response.errors);
          this.showMessage("paymentPage." + this.error.code);
        }
        if (response.organisation) {
          let userOrganisation: UserOrganisation = this.userData.selectedOrganisation;
          userOrganisation.summary = response.organisation.summary;
          this.storeUserOrganisationSummary(this.events, userOrganisation, 'peti:Deposit');
          this.showMessageWithAmount(this.config.currency + ' ' + value.amount, 'paymentPage.onlineSuccessCashPaymentOnly');
          this.paymentForm.reset();
          this.reset();
        };
      }, error => {
        this.logger.logData(this.logger.ERROR, "paymentPage:payCashMoneyOnline", error);
        super.showMessage("systemError");
      }
      );
  }
  showPrompt(value: any) {
    let prompt = this.alertCtrl.create({
      title: 'Login',
      message: "Please verify your transaction pin",
      inputs: [
        {
          name: 'Pin',
          placeholder: 'Enter your pin number'
        },
      ],
      buttons: [
        {
          text: 'Cancel',
          handler: data => {

          }
        },
        {
          text: 'Login',
          handler: data => {
            console.log("verify pin" + JSON.stringify(data) + this.userData.profile.pin);
            if (data.Pin == this.userData.profile.pin) {
              this.payCashMoneyOnline(value);
            } else {
              super.showMessage("You are not authorised as your pin is invalid");
            }
          }
        }
      ]
    });
    prompt.present(); */
  }
}