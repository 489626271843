
/**
 * Shared Data Cache
 * 
 * @copyright Digitalpeti Technologies Ltd. 2017-18
 */
// Core
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthorizationToken } from '../models/base/authorization-token.model';

const Constants = {
    MAX_TRANSFER_DATA_LENGTH: 10,
    MAX_LENGTH_CHECKER_STRING_LENGTH: 500,
    PATTERN_CHECKER_STRING_LENGTH: 250
}


@Injectable({
  providedIn: 'root'
})
/**
 * Shared Data Cache Service
 */
export class SharedDataCacheService {
    private static _properties: Object = {};
    private static _exclusionList: Array<string> = new Array<string>();
    private static _authorization: AuthorizationToken = null;

    /**
     * Initialize
     */
    constructor(private httpService: HttpClient) { }

    getShiftAssignStatusList(): any {
    return [
      
  ]
  }

    /**
     * Gets Property Value
     * @param name Property Name
     * @return Property Value
     */
    public static getProperty(name: string): any {
        if (!name) {
            return null;
        }
        return SharedDataCacheService._properties[name];
    }

    /**
     * Gets Properties
     */
    public static getProperties(): any {
        return SharedDataCacheService._properties;
    }

     /**
     * Get Router Exclusion List
     */
    public static getRouterExclusionList() {
        return SharedDataCacheService._exclusionList;
    }

    /**
     * Gets Shared Authorization Information
     * 
     * @return Authorization Information
     */
    public static getAuthorizationToken(): AuthorizationToken {
        return SharedDataCacheService._authorization;
    }
    
}