import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from 'src/app/core/security/authguard';
import {DepositComponent} from './deposit/deposit.component';
import {PassbookComponent} from './passbook/passbook.component';
import {PaymentComponent} from './payment/payment.component';
import {OnlinePaymentComponent} from './payment/online-payment/online-payment.component';
import {OfflinePaymentComponent} from './payment/offline-payment/offline-payment.component';

const routes: Routes = [
  {
      path: 'donation',
      children: [{
          path: 'donate',
          component: DepositComponent,
          canActivate: [AuthGuardService]
      },
      {
          path: 'statement',
          component: PassbookComponent,
          canActivate: [AuthGuardService]
      },
      {
          path: 'payment',
          component: PaymentComponent,
          canActivate: [AuthGuardService]
      },
      {
          path: 'onlinepayment',
          component: OnlinePaymentComponent,
          canActivate: [AuthGuardService]
      },
      {
          path: 'recordpayment',
          component: OfflinePaymentComponent,
          canActivate: [AuthGuardService]
      }]
  }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DonationRoutingModule { }
