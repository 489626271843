import { Injectable } from '@angular/core';

/**
 *  Base Service
 *
 * @copyright Digitalpeti Technologies Ltd. 2017-18
 */
// Angular

/**
 *  Base Service
 */

@Injectable({
    providedIn: 'root'
})
export abstract class BaseService {

    /**
     * Initialize
     */
    constructor() {
    }

    /**
     * Destroy Service
     */
    public destroy(): void {
    }
}
