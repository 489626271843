/**
 * CommonService
 * 
 * @copyright 
 */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { Observable } from 'rxjs';
import { UserProfileService } from 'src/app/providers/service/user-profile.service';
import { serviceBaseURL_CS } from 'src/environments/environment';
import { BaseResponse } from '../../core/models/response/base-response.model';
import { RestService } from '../../core/services/restful/rest.service';
//import { AlertValueDialogComponent } from '../../common/components/dialog-box/alert-value-dialog.component';
import { DialogBoxComponent } from '../../common/components/dialog-box/dialog-box.component';
import { ResourceFormation } from '../../common/models/common.model';
import { ApplicationConfigurationService } from '../../common/services/application-configuration.service';
import { CommonBaseService } from './common-base.service';
import { OrganisationDetails } from '../cdm/organisationDetails';
import { UserOrganisation } from '../cdm/userOrganisation';

//import {Notes} from '../../providers/cdm/organisationDetails';
@Injectable({
    providedIn: 'root'
})
export class CommonService {
    private options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
    constructor(public dialog: MatDialog,
        public http: HttpClient,
        private userProfileService: UserProfileService,
        private applicationConfigurationService: ApplicationConfigurationService,
        private restService: RestService,
        private commonBaseService: CommonBaseService) {
    }

    // showDialogBox
    showDialogBox(type, message) {
        this.dialog.open(DialogBoxComponent, {
            data: { type: type, message: message }
        });
    }

    groupArrayUsingKey(array, key) {
        let groupByKey = {};
        array.forEach(function (a) {
            groupByKey[a[key]] = groupByKey[a[key]] || [];
            groupByKey[a[key]].push(a);
        });
        return groupByKey;
    }

    getValueByFormField(searchForm, fieldName) {
        return searchForm.controls[fieldName].value;
    }

    getSum(...args: any[]) {
        let sum = 0;
        args.forEach(element => {
            sum = sum + parseInt(element)
        });
        return sum;
    }

    logError(domain, feature, error) {
        if (this.userProfileService && this.userProfileService.accessToken) {
            this.commonBaseService.error(domain, feature, error).subscribe(respose => {
                console.log(respose);
            });
        }
    }

    changeToUpperCase(e) {
        e.target.value = e.target.value.toUpperCase();
    }

    /**
     * To get value attribute of object on basis of id attribute from Array
     * @param listArray Array from which value attribute is to be return
     * @param key Key to match condition
     */
    getValueOfObjectFromListByKey(listArray: Array<any>, key) {
        if (key) {
            let list = listArray.find(function (element) {
                return element['id'] === key;
            });
            return list['value'];
        } else {
            return '';
        }
    }

    sortArrayByKey(array, key) {
        array.sort((a, b) => a[key] - b[key] ? -1 : 1)
        return array;
    }

    /**
    * HTTP service use to call
    * @param
    * @returns Observable<BaseResponse<any>>
    */
    getVisitedFunctions(staffNumber) {
        var searchparams = "?staffNumber=" + staffNumber;
        return this.restService.get('/assets/mocks/visitedfunction.json') as Observable<BaseResponse<any>>;
    }

    /**
    * Get system parameter value
    * @param code 
    * @param systemParams 
    */
    getSystemParamValue(code, systemParams) {
        let rowData = systemParams.find(x => x.code === code);
        if (rowData !== undefined) {
            return rowData.systemParameterValue;
        } else {
            return '';
        }
    }

    /**
    * HTTP service use to call get system params
    * @returns Observable<BaseResponse<any>>
    */
    getSystemParams() {
        return this.http.get("/assets/masterdataconfig/systemparameters.json") as Observable<BaseResponse<any>>;
    }

    /**
    * HTTP service use to call get system menus
    * @returns Observable<BaseResponse<any>>
    */
    getMenus() {
        return this.http.get("/assets/masterdataconfig/menus.json") as Observable<BaseResponse<any>>;
    }

    getMembersList(reqData: any)
    {
        return this.http.get("/assets/masterdataconfig/members.json") as Observable<BaseResponse<any>>;
    }
    getOrganisationList()
    {
        return this.http.get("/assets/masterdataconfig/org.json") as Observable<BaseResponse<OrganisationDetails>>;
      
    }
    getNoticeList()
    {
        return this.http.get("/assets/masterdataconfig/notice.json") as Observable<BaseResponse<OrganisationDetails>>;
    }
    
    getUserOrganisationDetails()
    {
        return this.http.get("/assets/masterdataconfig/userOrg.json") as Observable<BaseResponse<UserOrganisation>>;
    }
}