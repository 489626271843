/**
 * Component
 * 
 * @copyright Digitalpeti Technologies Ltd.
 */

import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DialogData } from '../models/dialog-data-model';

@Component({
  selector: 'app-dialog-box',
  templateUrl: './dialog-box.component.html',
  styleUrls: ['./dialog-box.component.scss']
})
export class DialogBoxComponent implements OnInit, AfterViewInit {

  mssageType = 'S';
  message = '';
  constructor(
    public dialogRef: MatDialogRef<DialogBoxComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit() {
    if (this.data !== null) {
      this.mssageType = this.data.type;
      this.message = this.data.message;
      if (this.mssageType === 'S' && this.message === '') {
        this.message = "Record saved successfully.";
      } else if (this.mssageType === 'S' && !this.message) {
        this.message = "Record saved successfully.";
      }
    } else {
      this.message = "Record saved successfully.";
    }
  }
  ngAfterViewInit() {
    if (this.data === null || this.mssageType === 'S' || this.mssageType === 'D') {
      setTimeout(() => {
        //if (this.data === null) { }
        this.dialogRef.close();
      }, 2000)
    }
  }

}
