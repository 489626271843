/**
 *  Rest HTTP Service
 *
 * @copyright Digitalpeti Technologies Ltd. 2017-18
 */
// Angular
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpEvent } from '@angular/common/http';
// Core
import { BaseService } from '../base.service';
import { NewHTTPService } from '../http/http.service';
// import { HTTPContentType, BaseRequest, BaseResponse, BaseResponseData } from '../../common/base.data';
import { EventSubject, BroadcastEvent } from '../event.service';
import { ServiceUtility } from '../../util/service.utility';
import { RestfulService, RestServiceEvents } from './rest.service';
import { BaseRequest } from '../../models/request/base-request.model';
import { BaseResponseData, BaseResponse } from '../../models/response/base-response.model';
import { HTTPContentType } from '../../models/base/http-content-type.model';

/**
 * New Rest Serivice Factory
 *
 * @param httpService HTTP Service
 */
export const NewRestServiceFactory = (httpService: NewHTTPService) => {    
    return ServiceUtility.resolveRestService(new NewRestService(httpService));
};

/**
 *  New Restful HTTP Service
 */
@Injectable()
export class NewRestService extends BaseService implements RestfulService {

    /**
     * Initialize
     */
    constructor(private httpService: NewHTTPService) {
        super();
        // console.debug("Creating New Rest Service...!");
    }

    /**
     * Gets Notification Observer for Subscription
     */
    // public getRestEventObserver(): Observable<BroadcastEvent> {
    //     return ServiceUtility.getEventService().getEventObserver(EventSubject.RESTFUL_NOTIFICATION);
    // }

    /**
     * Gets Data (Async)
     *
     * @param url URL
     * @param data Data
     * @param observe Kind of Data Return by Observeable (default is body)
     */
    public get(url: string, observe?: string, headerData?: any): Observable<BaseResponse<BaseResponseData>> {
        // Fix! Multiple Angular HTTP Call Goes On for Each Subscription
        const jobSubject = new Subject<any>();
        const observer = jobSubject.asObservable();
        // Notify of Rest Call Start
        this.raiseRestfulEvent(url, RestServiceEvents.GET_BEGIN);
        // Rest Call (Async)
        setTimeout(() => {
            const httpObserver: Observable<HttpEvent<any>> = this.httpService.get(url, HTTPContentType.JSON, observe, headerData);
            // Subscribe for Completion State
            if (httpObserver) {
                httpObserver.subscribe((next) => {
                    jobSubject.next(next);
                    // Send Completion Event; Success
                    this.raiseRestfulEvent(url, RestServiceEvents.GET_SUCCESS);
                }, (error) => {
                    jobSubject.error(error);
                    // Send Completion Event; Failure
                    this.raiseRestfulEvent(url, RestServiceEvents.GET_FAILURE);
                    // Send Completion Event (Not Send on Error)
                    this.raiseRestfulEvent(url, RestServiceEvents.GET_END);
                }, () => {
                    jobSubject.complete();
                    // Send Completion Event
                    this.raiseRestfulEvent(url, RestServiceEvents.GET_END);
                });
            } else {
                jobSubject.complete();
                // Send Completion Event
                this.raiseRestfulEvent(url, RestServiceEvents.GET_END);
            }
        }, 0);
        //
        return observer;
    }

    /**
     * Post Data (Async)
     *
     * @param url URL
     * @param data Data
     * @param clean Clean Data
     * @param observe Kind of Data Return by Observeable (default is body)
     */
    private postData(url: string, data: BaseRequest, clean?: boolean, observe?: string): Observable<BaseResponse<BaseResponseData>> {
        console.log("Laxmi C Jainhttp rest new service");
        // Fix! Multiple Angular HTTP Call Goes On for Each Subscription
        const jobSubject = new Subject<any>();
        const observer = jobSubject.asObservable();
        // Notify of Rest Call Start
        this.raiseRestfulEvent(url, RestServiceEvents.POST_BEGIN);
        // Rest Call (Async)
        setTimeout(() => {
            const httpObserver: Observable<HttpEvent<any>> = this.httpService.post(url, HTTPContentType.JSON, data, observe);
            // Subscribe for Completion State
            if (httpObserver) {
                httpObserver.subscribe((next) => {
                    jobSubject.next(next);
                    // Send Completion Event; Success
                    this.raiseRestfulEvent(url, RestServiceEvents.POST_SUCCESS);
                }, (error) => {
                    jobSubject.error(error);
                    // Send Completion Event; Failure
                    this.raiseRestfulEvent(url, RestServiceEvents.POST_FAILURE);
                    // Send Completion Event (Not Send on Error)
                    this.raiseRestfulEvent(url, RestServiceEvents.POST_END);
                }, () => {
                    jobSubject.complete();
                    // Send Completion Event
                    this.raiseRestfulEvent(url, RestServiceEvents.POST_END);
                });
            } else {
                jobSubject.complete();
                // Send Completion Event
                this.raiseRestfulEvent(url, RestServiceEvents.POST_END);
            }
        }, 0);
        //
        return observer;
    }

    /**
     * Post Data (Async)
     *
     * @param url URL
     * @param data Data
     * @param observe Kind of Data Return by Observeable (default is body)
     */
    post(url: string, data: BaseRequest, observe?: string): Observable<BaseResponse<BaseResponseData>> {
        console.log("Laxmi C Jainhttp rest new service 1111");
        return this.postData(url, data, true, observe);
    }

     /**
     * Put Data (Async)
     *
     * @param url URL
     * @param data Data
     * @param observe Kind of Data Return by Observeable (default is body)
     */
    put(url: string, data: BaseRequest, observe?: string): Observable<BaseResponse<BaseResponseData>> {
        return this.postData(url, data, true, observe);
    }

    /**
     * Post Data (Async)
     *
     * @param url URL
     * @param data Data
     * @param observe Kind of Data Return by Observeable (default is body)
     */
    cleanAndPost(url: string, data: BaseRequest, observe?: string): Observable<BaseResponse<BaseResponseData>> {
        console.log("Laxmi C Jainhttp rest new service 2222");
        return this.postData(url, data, true, observe);
    }

    /**
     * Raise Event
     *
     * @param url URL
     * @param eventId Event Id
     */
    private raiseRestfulEvent(url: string, eventId: string) {
        // Send Event of Completion
        const event: BroadcastEvent = new BroadcastEvent();
        // Event Id
        event.subject = EventSubject.RESTFUL_NOTIFICATION;
        event.eventId = eventId;
        event.data = url;
        // Raise Event
        ServiceUtility.getEventService().raiseEvent(event);
    }

    /**
     * Destroy Service
     */
    public destroy(): void {
        this.httpService.destroy();
    }
}
