import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgGridModule } from 'ag-grid-angular';

import { DonationRoutingModule } from './donation-routing.module';
import { DepositComponent } from './deposit/deposit.component';
import { PassbookComponent } from './passbook/passbook.component';
import { PaymentComponent } from './payment/payment.component';
import { OfflinePaymentComponent } from './payment/offline-payment/offline-payment.component';
import { OnlinePaymentComponent } from './payment/online-payment/online-payment.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { LoggingService } from '../../../providers/service/logger/logging.service';

@NgModule({
  declarations: [DepositComponent, PassbookComponent, PaymentComponent, OfflinePaymentComponent, OnlinePaymentComponent],
  imports: [
    CommonModule,
    DonationRoutingModule,
    ReactiveFormsModule,
    SharedModule,
    TranslateModule,
    AgGridModule.withComponents([])     
  ],providers: [LoggingService
  ]
})
export class DonationModule { }
