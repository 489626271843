import { Component, OnInit } from '@angular/core';
import { OrganisationDetails } from '../../../../providers/cdm/organisationDetails';
import { SharedService } from 'src/app/providers/service/shared-services.service';

@Component({
  selector: 'app-sponser',
  templateUrl: './sponser.component.html',
  styleUrls: ['./sponser.component.scss']
})
export class SponserComponent implements OnInit {

  organisations: OrganisationDetails;
  constructor(private sharedService: SharedService) {
    this.organisations = this.sharedService.organisation;
   }

  ngOnInit() {
  }

}
