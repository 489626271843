/**
 * Service
 * 
 * @copyright Digitalpeti Technologies Ltd.
 */

import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { throwError } from 'rxjs';
import { ApplicationConfigurationService } from 'src/app/common/services/application-configuration.service';
import { LoggingService } from '../logger/logging.service';
import { ErrorService } from './error.service';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root',
})
export class GlobalErrorHandler implements ErrorHandler {

  constructor(private injector: Injector,
    private applicationConfigurationService :ApplicationConfigurationService, 
    private notifier:NotificationService) {

  }

  handleError(error: any | HttpErrorResponse) {
    const errorService = this.injector.get(ErrorService);
    const logger = this.injector.get(LoggingService);
    //const notifier = this.injector.get(NotificationService);

    let message;
    let stackTrace;
    let customError = Object.create(error);
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    
    // Reload the application if chunk loading fails
    if (chunkFailedMessage.test(error.message)) {
      window.location.reload();
    } else if (error instanceof HttpErrorResponse) {
      // Server error
      message = errorService.getServerErrorMessage(customError);
      //stackTrace = errorService.getServerErrorStackTrace(error);
      this.notifier.showError(message);
    } else {
      // Client Error
      message = errorService.getClientErrorMessage(error);
      if (message !== "Cannot read property 'instance' of undefined") {
        this.notifier.showError(message);
      }
    }
    // Always log errors
    logger.logError(message, stackTrace);
    return throwError(message);
  }

  handleErrorNoPopup(error: any | HttpErrorResponse) {
    const errorService = this.injector.get(ErrorService);
    const logger = this.injector.get(LoggingService);
    const notifier = this.injector.get(NotificationService);

    let message;
    let stackTrace;
    let customError = Object.create(error);
    if (error.name === 'TimeoutError') {
      message = errorService.getTimeOutErrorMessage(error);
      notifier.showError(message);
    } else
      if (error instanceof HttpErrorResponse) {
        // Server error
        message = errorService.getServerErrorMessage(customError);
        //stackTrace = errorService.getServerErrorStackTrace(error);
        // notifier.showError(message);
      } else {
        // Client Error
        message = errorService.getClientErrorMessage(error);
        // notifier.showError(message);
      }
    // Always log errors
    logger.logError(message, stackTrace);
    return throwError(message);
  }

  handleErrorRosterPopup(error: any | HttpErrorResponse) {
    const errorService = this.injector.get(ErrorService);
    const logger = this.injector.get(LoggingService);
    const notifier = this.injector.get(NotificationService);

    let message;
    let stackTrace;
    let customError = Object.create(error);
    if (error instanceof HttpErrorResponse) {
      // Server error
      if (error.status == 500) {
        message = this.applicationConfigurationService.ROSTER_INVALID_FILE;
      } else {
        message = errorService.getServerErrorMessage(customError);
      }
      //stackTrace = errorService.getServerErrorStackTrace(error);
      notifier.showError(message);
    } else {
      // Client Error
      message = errorService.getClientErrorMessage(error);
      notifier.showError(message);
    }
    // Always log errors
    logger.logError(message, stackTrace);
    return throwError(message);
  }
}