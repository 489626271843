import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: '', loadChildren: () => import('./domain/digitalpeti/login/login.module').then(m => m.LoginModule) },
  { path: 'help', loadChildren: () => import('./domain/help/help.module').then(m => m.HelpModule) },
  { path: 'org', loadChildren: () => import('./domain/digitalpeti/organisation-details/organisation-details.module').then(m => m.OrganisationDetailsModule) },
  { path: 'members', loadChildren: () => import('./domain/members/member.module').then(m => m.MemberModule) },
  { path: 'donation', loadChildren: () => import('./domain/digitalpeti/donation/donation.module').then(m => m.DonationModule) },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
