import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogBoxComponent } from 'src/app/common/components/dialog-box/dialog-box.component';
import { MatDialog } from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
public notifyFlag:boolean = false;

  constructor(
    public snackBar: MatSnackBar,
    private zone: NgZone,
    private dialog: MatDialog) { }

  showSuccess(message: string): void {
    // Had an issue with the snackbar being ran outside of angular's zone.
    this.zone.run(() => {
      this.snackBar.open(message);
    });
  }

  showError(message: string): void {
    this.zone.run(() => {
      // The second parameter is the text in the button. 
      // In the third, we send in the css class for the snack bar.
      // this.dialog.ngOnInit();
      if(!this.notifyFlag){
      this.openDialog(message);
      }
      // this.snackBar.open(message, 'X', {panelClass: ['error']});
    });
  }

  /**
  * This method used to initialize provider event and service call.
  */
  openDialog(message: string) {
    this.notifyFlag =true;
    const dialogRef = this.dialog.open(DialogBoxComponent, {
      data: { type: 'E', message }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.notifyFlag = false;
      console.log(`Dialog result: ${result}`);
    });
  }

}