import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from 'src/app/core/security/authguard';
import { DashboardComponent } from '../login/dashboard/dashboard.component';
import { LoginHomeComponent } from '../login/login-home/login-home.component';
import { RegistrationHomeComponent } from '../login/registration-home/registration-home.component';
import { ResetpasswordComponent } from "../login/resetpassword/resetpassword.component";
import { VerifyaccountComponent } from "../login/verifyaccount/verifyaccount.component";

const routes: Routes = [
  {
    path: '',
    component: LoginHomeComponent,
    pathMatch: 'full'
  },
    {
      path: 'login',
      component: LoginHomeComponent,
      pathMatch: 'full'
    },
    {
      path: 'register',
      component: RegistrationHomeComponent,
      pathMatch: 'full'
    },
    {
      path: 'forgetpassword',
      component: ResetpasswordComponent,
      pathMatch: 'full'
    },
    {
      path: 'VerifyaccountPage',
      component: VerifyaccountComponent,
      pathMatch: 'full'
    },
    {      
      path: 'dashboard',
      component: DashboardComponent,
      pathMatch: 'full'
    },
    {      
      path: 'gulak',
      component: DashboardComponent,
      canActivate: [AuthGuardService]
    },
    
  ];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LoginRoutingModule { }
