import { Component, Inject } from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ApplicationConfigurationService } from 'src/app/common/services/application-configuration.service';

@Component({
  selector: 'confirmation-component',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmPopupComponent { 
  popupHeading: string = "Confirmation Popup";
  confirmMessage : string = "Confirmation Message";
  docId : any;
  public yesString: string = "Yes";
  public NoString: string = "No";

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private applicationConfigurationService: ApplicationConfigurationService) {
      this.popupHeading = data.popupHeading;
      this.confirmMessage = data.confirmMessage;
      this.docId = data.docId;
    }
}
